import dialogLoader from "@/store/loaders/dialog"
import listingLoader from "@/store/loaders/listing";

import EmployeeEdit from "./EmployeeEdit.vue"

import { mapState, mapGetters } from "vuex";

import swalStatic from "@/libs/functions/swal";

import AppBar from '@/components/Interface/AppBar';
import DatatablePagination from '@/components/Interface/DatatablePagination';

import validationMixin from "@/libs/mixins/validation";

import employeeStatic from '@/libs/functions/employee';

import numberStatic from '@/libs/functions/number'

export default {
	components: {
		EmployeeEdit,
		AppBar,
		DatatablePagination
	},
	mixins: [
		listingLoader,
		dialogLoader,
		validationMixin
	],
	computed: {
		...mapGetters("employees", [
				"getContractTypes"
			]
		),
		...mapState({
			employees: state => state.employees.list,
			table_headers: state => state.employees.table_headers,
			isLoading: "isLoading",
			total_items: state => state.employees.total_items
		})
	},
	data: () => ({
		name: 'employees',
		dialogs: {
			main_object: {
				edit: false,
			}
		},
		headers() {
			return []
		}
	}),
	created(){
		this.options = Object.assign({}, this.default_options)
	},
	methods: {
		onEditFormSaved() {
			this.$store.dispatch(this.name + '/fetch')
		},
		onListFormHide() {
			this.edited_item = {}
			this.window_visible = false
		},
		restoreItem(item) {
			swalStatic.ask(this, function (parent) {
				parent.$store.dispatch(parent.name + '/restore', {
					id: item.id,
				})
			}, "restaurar", "seguro_que_deseas_restaurar_esto")
		},
		deleteItem(item){
			swalStatic.ask(this, function(parent){
				parent.$store.dispatch(parent.name + '/quickUpdate', {
					id: item.id,
					deleted_at: new Date()
				})
			})
		},
		toCurrencyString: numberStatic.toCurrencyString,
      getEmployementLetter: function(employee){
         let payload = {
            parent: this,
            data: {
               employee: employee,
               display_salary: confirm("¿Desea mostrar el salario en el comprobante? Aceptar/ok = Si. Cancelar = No"), 
               address_to: prompt("¿A quién va dirigida la carta?. Deja el campo vacío para mostrar 'A quien pueda interesar'")
            }
         }
         return employeeStatic.getEmployementLetter(payload)
      }
	}
};
