<template>
	<div v-if="authenticated">

		<UserEdit
			:visible="dialogs.user.edit"
			@hide="dialogs.user.edit = false"
			v-if="$can('edit', 'User')"
			:active_item="{}"
		></UserEdit>

		<EmployeeEdit
			:visible="dialogs.employee.edit"
			@hide="dialogs.employee.edit = false"
			v-if="$can('edit', 'Employee')"
			:active_item="{}"
		></EmployeeEdit>

		<WorkContractEdit
			:visible="dialogs.work_contract.edit"
			@hide="dialogs.work_contract.edit = false"
			v-if="$can('edit', 'WorkContract')"
			:active_item="{}"
		></WorkContractEdit>

		<v-app-bar dense dark class="main-color-bg darken-5">
			<v-icon class="mr-3" @click.stop="setDrawerVisible"
				>{{ 'mdi-menu' }}
			</v-icon>

			<v-spacer></v-spacer>

			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<v-spacer></v-spacer>
			
		</v-app-bar>

		<v-container>
			<v-row dense>
				<v-col cols="12" md="5">
					<div class="pa-2">
						<h4>¡Bienvenid@ de nuevo, {{ user.name.split(' ').slice(0, 2).join(' ') }}!</h4>
						<div class="pb-2">
							📅 Hoy es {{ getFormattedDate(new Date(), 'long_with_week_day', 'es-CO', 'America/Bogota') }} 💪🚀
						</div>
						<small> <i>{{ $store.getters.getRandomMotivation }}</i> 😎😎<br>— Anónimo </small> <br />
					</div>
				</v-col>
				<v-col cols="12" md="7">
					<v-row dense class="pa-0">
						<v-col cols="6" sm="3" >
							<v-card outlined :disabled="!$can('list', 'User')">
								<router-link to="/users">
									<v-img
										:src="$public_url + 'iconos/Usuarios.png'"
										class="white--text align-end v-img-link"
										:gradient="
											$vuetify.theme.dark
												? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
												: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
										"
										height="100px"
										contain
										title="Usuarios"
									>
									</v-img>
								</router-link>

								<v-card-actions>
									<v-spacer></v-spacer>

									<v-btn
										icon
										class="green--text"
										@click="dialogs.user.edit = true"
										:disabled="!$can('create', 'User')"
									>
										<v-icon>mdi-account-plus</v-icon>
									</v-btn>
									<router-link to="/users" tag="button" :disabled="!$can('list', 'User')">
										<v-btn icon class="blue--text" :disabled="!$can('list', 'User')">
											<v-icon>mdi-format-list-bulleted</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col>

						<v-col cols="6" sm="3">
							<v-card outlined  :disabled="!$can('list', 'Employee')">
								<router-link to="/employees">
									<v-img
										:src="$public_url + 'iconos/Empleados.png'"
										class="align-end v-img-link"
										:gradient="
											$vuetify.theme.dark
												? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
												: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
										"
										height="100px"
										contain
										title="Empleados"
									>
									</v-img>
								</router-link>
								<v-card-actions>

									<v-spacer></v-spacer>

									<v-btn
										icon
										class="green--text"
										@click="dialogs.employee.edit = true"
										:disabled="!$can('create', 'Employee')"
									>
										<v-icon>mdi-account-multiple-plus</v-icon>
									</v-btn>

									<router-link to="/employees" tag="button" :disabled="!$can('list', 'Employee')">
										<v-btn icon class="blue--text" :disabled="!$can('list', 'Employee')">
											<v-icon>mdi-format-list-bulleted</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col>

						<v-col cols="6" sm="3">
							<v-card outlined  :disabled="!$can('list', 'WorkContract')">
								<router-link to="/work_contracts">
									<v-img
										:src="$public_url + 'iconos/Contratos.png'"
										class="align-end v-img-link"
										:gradient="
											$vuetify.theme.dark
												? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
												: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
										"
										height="100px"
										contain
										title="Contratos"
									>
									</v-img>
								</router-link>
								<v-card-actions>

									<v-spacer></v-spacer>

									<v-btn
										icon
										class="green--text"
										@click="dialogs.work_contract.edit = true"
										:disabled="!$can('create', 'WorkContract')"
									>
										<v-icon>mdi-account-multiple-plus</v-icon>
									</v-btn>

									<router-link to="/work_contracts" tag="button" :disabled="!$can('list', 'WorkContract')">
										<v-btn icon class="blue--text" :disabled="!$can('list', 'WorkContract')">
											<v-icon>mdi-format-list-bulleted</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col>

						<!--
						
						<v-col cols="6" sm="3">
							<v-card outlined :disabled="!$can('list', 'Plan')">
								<router-link to="/products">
									<v-img
										:src="$public_url + 'iconos/Planes.png'"
										class="white--text align-end v-img-link"
										:gradient="
											$vuetify.theme.dark
												? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
												: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
										"
										height="100px"
										contain
										:title="$t('planes')"
									>
									</v-img>
								</router-link>

								<v-card-actions>
									<v-spacer></v-spacer>

									<v-btn
										icon
										class="green--text"
										@click="dialogs.plan.edit = true"
										:disabled="!$can('create', 'Plan')"
									>
										<v-icon>mdi-plus-circle</v-icon>
									</v-btn>
									<router-link to="/products" tag="button" :disabled="!$can('list', 'Plan')">
										<v-btn
											icon
											class="blue--text"
											:disabled="!$can('list', 'Plan')"
										>
											<v-icon>mdi-format-list-bulleted</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col>

						<v-col cols="6" sm="3">
							<v-card outlined :disabled="!$can('list', 'Trip')">
								<router-link to="/trips">
									<v-img
										:src="$public_url + 'iconos/Viajes.png'"
										class="white--text align-end v-img-link"
										:gradient="
											$vuetify.theme.dark
												? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)'
												: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)'
										"
										height="100px"
										contain
										:title="$t('programacion')"
									>
									</v-img>
								</router-link>

								<v-card-actions>
									<v-spacer></v-spacer>

									<router-link to="/events_calendar" tag="button" :disabled="!$can('list', 'Trip')">
										<v-btn
											icon
											class="blue--text"
											:disabled="!$can('list', 'Trip')"
										>
											<v-icon>mdi-calendar-month-outline</v-icon>
										</v-btn>
									</router-link>

									<router-link to="/product_schedules" tag="button" :disabled="!$can('list', 'PlanSchedule')">
										<v-btn
											icon
											class="green--text"
											:disabled="!$can('list', 'PlanSchedule')"
										>
											<v-icon>mdi-calendar-plus</v-icon>
										</v-btn>
									</router-link>								

									<router-link to="/trips" tag="button" :disabled="!$can('list', 'Trip')">
										<v-btn
											icon
											class="blue--text"
											:disabled="!$can('list', 'Trip')"
										>
											<v-icon>mdi-format-list-bulleted</v-icon>
										</v-btn>
									</router-link>
								</v-card-actions>
							</v-card>
						</v-col> -->
					</v-row>
				</v-col>
			</v-row>
		</v-container>		
		
	</div>
</template>

<script>
	import home from '@/views/Home/Home'
	export default home
</script>

<style scoped src="@/views/Home/Home.sass" lang="sass"></style>
