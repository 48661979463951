import Vue from 'vue'
import VueRouter from 'vue-router'

import SignIn from '../views/User/SignIn.vue'

import Home from '../views/Home/Home.vue'
import Employees from '../components/Employee/EmployeeList.vue'
import WorkContracts from '../components/WorkContract/WorkContractList.vue'
import Users from '../components/User/UserList.vue'

import Options from "@/components/Option/OptionEdit.vue"
import Roles from "@/components/Role/RoleList.vue"
import Stats from "@/components/Stats/StatsHome.vue"

import PageNotFound from '../views/Home/PageNotFound.vue'

import EmploymentCertificateForm from '../views/Employees/CertificateForm/CertificateForm.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/employees',
        name: 'employees',
        component: Employees,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/work_contracts',
        name: 'work_contracts',
        component: WorkContracts,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },

    {
        path: '/options',
        name: 'options',
        component: Options,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },

    {
        path: '/roles',
        name: 'roles',
        component: Roles,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },

    {
        path: '/stats',
        name: 'stats',
        component: Stats,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/signin',
        name: 'signin',
        component: SignIn,
        beforeEnter: (to, from, next) => {

            if (store.getters['auth/authenticated']) {

                return next({
                    name: 'dashboard'
                })
            }

            next()
        }
    },
    {
        path: '/employment_cf',
        name: 'employment_cert_form',
        component: EmploymentCertificateForm        
    },
    { path: "*", component: PageNotFound }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router