<template>
	<v-card class="pa-5" flat>
      <v-alert
         icon="mdi-shield-lock-outline"
         prominent
         text
         type="info"
      >
         Necesitas permisos para acceder a este recurso.
      </v-alert>
   </v-card>
</template>

<script>
	export default {
		methods: {},
	}
</script>
