/**
 * Updates the state.list property over the caller state
 * @param {Object} state Current parent caller state
 * @param {Object} payload Contain the data being added to the state items list
 * 
 */
function setItemsList(state, payload) {
   if (typeof payload !== 'object') {
      throw "InvalidPayloadObjectException"
   }

   if (!payload.data) {
      throw "InvalidPayloadDataObjectException"
   }

   if (typeof payload.append === undefined) {
      throw "InvalidPayloadAppendException"
   }

   if (!payload.data.items) {
      throw "InvalidPayloadDataItemsException"
   }

   if (payload.data) {
      if (payload.append) {
         state.list.push.apply(state.list, payload.data.items);
      } else if (payload.data.items) {
         state.list = payload.data.items
      }
   } else {
      state.list = []
   }
   state.total_items = payload.data.total;
}

/**
 * Exports all functions to enable dot notation access
 */
export default {
   setItemsList
}