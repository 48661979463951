<template>
	<v-dialog
		v-model="window_visible"
		transition="fade-transition"
		persistent no-click-animation
		overlay-opacity="0.8"
		:fullscreen="$vuetify.breakpoint.xsOnly"
		:retain-focus="false"
		width="650px"
	>
		<v-app-bar
			:absolute="$vuetify.breakpoint.xsOnly"
			dark
			color="darken-4"
			class="main-color-bg"
			dense
		>
			<v-btn icon dark @click="window_visible = false" :title="$t('cancelar')">
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<v-toolbar-title>Contrato de Trabajo</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon dark @click="save()" :title="$t('guardar')">
				<v-icon>mdi-content-save</v-icon>
			</v-btn>

		</v-app-bar>

		<v-card>
			<v-card-text>
				<v-row>
					
					<v-col cols="12">
						<strong>Información del Empleado</strong>
					</v-col>

					<v-col cols="12">
						<v-autocomplete
							dense
							v-model="edited_item.employee_id"
							label="Empleado"
							:items="$store.getters.getEmployees"
							item-text="search_string"
							item-value="id"
							:return-object="false"
							outlined
							hide-details
						>
						</v-autocomplete>
					</v-col>

					<v-col cols="12">
						<strong>Información de contrato</strong>
					</v-col>

					<v-col cols="12">
						<v-text-field
							dense
							outlined
							label="Cargo"
							hide-details
							v-model="edited_item.job_title"
						>
						</v-text-field>
					</v-col>	

					<v-col cols="12" md="6">
						<v-autocomplete
							v-model="edited_item.contract_type"
							label="Tipo de contrato"
							outlined
							hide-details
							dense
							:items="$store.getters.getContractTypes"
							item-text="name"
							item-value="id"
							clearable
						></v-autocomplete>
					</v-col>
					
					<v-col cols="12" sm="6">
						<v-text-field
							dense
							outlined
							label="Fecha de inicio (inclusiva)"
							hide-details
							type="date"
							v-model="edited_item.start_date"
						>
						</v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
							dense
							outlined
							label="Fecha de finalización (inclusiva)"
							hide-details
							type="date"
							v-model="edited_item.end_date"
							clearable
						>
						</v-text-field>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
							dense
							outlined
							label="Salario mensual"
							type="number"
							min="0"
							hide-details
							v-model="edited_item.monthly_salary"
						>
						</v-text-field>
					</v-col>

					<v-col cols="12" sm="6" class="pt-5">
						<v-checkbox
							label="Ingresos fijos"
							dense
							hide-details
							v-model="edited_item.fixed_salary"
							class="mt-0 pt-0"							
						>
						</v-checkbox>
					</v-col>
					
					<v-col cols="12">
						<v-textarea
							dense
							outlined
							label="Observaciones"
							hide-details
							rows="2"
							v-model="edited_item.notes"
						>
						</v-textarea>
					</v-col>

					<v-col cols="12" sm="4">
						<v-select
							dense
							v-model="edited_item.status"
							label="Estado"
							:items="['vigente', 'finalizado']"
							item-value="item"
							:return-object="false"
							outlined
							hide-details
						>
							<template v-slot:selection="{ item }">
								{{ $t('iconos_estados.' + item) }} {{ $t('estados.' + item) }}
							</template>

							<template v-slot:item="{ item }">
								{{ $t('iconos_estados.' + item) }} {{ $t('estados.' + item) }}
							</template>
						</v-select>
					</v-col>
					
				</v-row>
				
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import dialogLoader from "@/store/loaders/dialog"
import validationMixin from "@/libs/mixins/validation"
import axiosStatic from "@/libs/functions/axios";
import authorizeMixin from "@/libs/mixins/authorize";


export default {
   data: () => ({
      name: "work_contracts",
      edited_item: {
         'employee_id': null,
         'start_date': null,
         'end_date': null,
         'type': null,
         'monthly_salary': null,
         'job_title': null,
         'status': 'activo',
         'fixed_salary': true,
      },
      default_item: {         
         'employee_id': null,
         'start_date': null,
         'end_date': null,
         'type': null,
         'monthly_salary': null,
         'job_title': null,
         'status': 'activo',
         'fixed_salary': true,
      },
      employee_list: []
   }),
   mixins: [
      dialogLoader,
      validationMixin,
      authorizeMixin
   ],
   watch: {
		"window_visible": function(visible){ 
         this.windowVisibleChanged(this, visible)
         console.log(this.$store.getters.getContractTypes)
      },
		"active_item": function(item){ this.activeItemChanged(this, item)}
	},
   methods: {
      save(){
         if(this.isNotEmpty(this.edited_item.document)){
            this.edited_item.document = this.edited_item.document.replace(/[^a-zA-Z0-9\-]/g, '')
         }
         
         if(this.isEmpty(this.edited_item.id) && this.isEmpty(this.edited_item.password)){
            this.edited_item.new_password = this.edited_item.document
            this.edited_item.new_confirm_password = this.edited_item.document
            this.edited_item.edit_password = true
         }
         axiosStatic.objectSave(this);
      }
   },
   computed: {
      getVisibleRoles(){
         if(this.sessionIsAdministrator()){
            return this.$store.getters.getRoles
         }
         return this.$store.getters.getRoles.filter(r => !this.hidden_roles.includes(r.value))
      },
      selectedRole(){
         if(this.isEmpty(this.edited_item.role)) return {}
         let role = this.$store.getters.getRoles.filter(r => {
            return r.value == this.edited_item.role
         })[0] ?? {}
         return role
      }
   }
};

</script>
