export default {
  state: {
    dialogs: {
      /**
       * Dialogs opened from home page
       */
      // 'Home.UserList': false,
      // 'Home.UserEdit': false,
      
      'Home.ClientEdit': false,
      'Home.ClientList': false,
      
      /**
       * Dialogs opened from UserList dialog
       */
      // 'UserList.UserEdit': false,

      /**
       * Dialogs opened from ClientList dialog
       */
      'ClientList.ClientEdit': false,

    }
  },
  getters: {
    getDialogs(state) {
      return state.dialogs
    }
  },
  mutations: {
    /**
     * 
     * @param {Object} state Current state
     * @param {Object} payload Object:
     *  {
     *    dialog: dialog alias,
     *     visible: true for visible, false for hidden
     *  }
     */
    setDialogVisible(state, payload) {
      state.dialogs[payload.dialog] = payload.visible
    }
  },
  actions: {
    setDialogVisible({ commit }, payload) {
      console.log("Now showint ", payload)
      commit('setDialogVisible', payload)
    }
  }
}