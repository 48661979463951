import store from '..'
import globalMixin from '@/libs/mixins/global'
import objectStatic from '@/libs/functions/object';


/**
 * Methods and properties that are mixed into every app dialog instance
 */
export default {
	props: {
		visible: Boolean,
		active_item: Object,
		active_parent_item: Object
	},
	mixins: [
		globalMixin
	],
	data: () => ({
		/**
		 * Default items for dialogs that edit objects, such as users, hotels, clients, etc
		 */
		edited_item: {},
		default_item: {}
	}),
	computed: {
		/**
		 * Handles dialog visibility
		 */
		window_visible: {
			get() {
				return this.visible;
			},
			set() {
				this.$emit("hide");
			},
		},
	},
	methods: {
		/**
		 * Initialises the component
		 */
		initialise() {

		},
		/**
		 * Shows the window loader
		 */
		startLoading() {
			store.dispatch('setLoading', true, { root: true })
		},
		/**
		 * Hides the window loader
		 */
		stopLoading() {
			store.dispatch('setLoading', false, { root: true })
		},

		/**
		 * Shows the window drawer
		 */
		showDrawer() {
			store.dispatch('setDrawerVisible', true, { root: true })
		},
		/**
		 * Hides the window drawer
		 */
		hideDrawer() {
			store.dispatch('setDrawerVisible', false, { root: true })
		},

		/**
		 * 
		 * @param {Object} _this Dialog instance
		 * @param {Boolean} visible Whether the dialog is being set visible
		 */
		windowVisibleChanged(_this, visible) {
			if (visible) {
				_this.initialise()
			}
		},
		/**
		 * 
		 * @param {Object} _this Dialog instance
		 * @param {Object} item Current item being edited, such as user item, client item
		 */
		activeItemChanged(_this, item, allow_non_id = false) {

			// If item is not object
			if (typeof item !== 'object') {
				throw 'InvalidObjectException';
			}

			// If item has an id
			if (item && item.id) {
				//_this.edited_item = Object.assign({}, _this.active_item);
				_this.edited_item = objectStatic.cloneObject(_this.active_item);
				return;
			}

			if (allow_non_id) {
				// If item has no id, but we allow non-id objects
				if (item && !item.id && allow_non_id) {
					//_this.edited_item = Object.assign({}, _this.active_item);
					_this.edited_item = objectStatic.cloneObject(_this.active_item);
					return;
				}
			}

			// If no id is found at all
			if (item && !item.id) {
				//_this.edited_item = Object.assign({}, _this.default_item);
				_this.edited_item = objectStatic.cloneObject(_this.default_item);
				return
			}
		},
		/**
		 * 
		 * @param {Object} _this Dialog instance
		 * @param {Object} item Current item being edited, such as user item, client item
		 */
		activeParentItemChanged(_this, item) {
			if (typeof item == 'object' && item.id) {
				//_this.parent_item = Object.assign({}, _this.active_parent_item);
				_this.parent_item = objectStatic.cloneObject(_this.active_parent_item);

			} else {
				//_this.parent_item = Object.assign({}, _this.default_parent_item);
				_this.parent_item = objectStatic.cloneObject(_this.default_parent_item);
			}
		},
		/**
		 * Resets an edited item to its defaults
		 */
		resetEditedItem() {
			this.edited_item = objectStatic.cloneObject(this.active_item);
		}
	}
}