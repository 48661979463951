import store from '@/store'
import dateStatic from "../functions/date";
import numberStatic from "../functions/number";
import swalStatic from "../functions/swal";

export default {
   methods: {
      /**
       * Extracts a specific list of value-based items from an array of objects 
       * @param {Array} all_items List of items from where extracted items will be taken
       * @param {Array} required_items List of items to be extracted from status array
       * @returns Array
       * 
       * _Samples_
       * 1) all_items = [{"value":"inactivo","text":"Inactivo"},...] 
       * 2) required_items = ['activo', 'inactivo']
       */
      filterStatuses(required_items) {
         let all_items = store.getters.getStatuses
         return all_items.filter(function (item) {
            return required_items.includes(item.value);
         })
      },
      /**
       * Handles expansion on direct children of a v-table
       * @param {object} item A first-level child item to be expanded
       * @param {boolean} state Expanded or not
       */
      handleExpansion(item, state) {
         const itemIndex = this.expanded.indexOf(item);
         state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
      },

      /**
       * Includes or excludes a given item from an array
       * @param {array} group V-model of expanded list
       * @param {object} item Item to be expanded
       */
      toggleFromGroup(group, item) {

         const index = group.indexOf(item.id);

         if (index >= 0) {
            group.splice(index, 1)
         } else {
            group.push(item.id);
         }

      },
      /**
       * 
       * @param {date} date Date to be formatted
       * @param {string} format Format to be applied, default: long
       * @param {string} locale Locale string, default: es-CO
       * @param {string} zone Time zone string, default: America/Bogota
       */
      getFormattedDate(date, format = 'long', locale = 'es-CO', timezone = 'UTC') {
         return dateStatic.toDateString(date, format, locale, timezone)
      },
      /**
       * 
       * @param {date} value Date to be formatted
       * @param {string} format Format to be applied, default: long
       * @param {string} locale Locale string, default: es-CO
       */
      getFormattedTime(value, format = 'default', locale = 'es-CO') {
         return dateStatic.toTimeString(value, format, locale)
      },
      /**
       * 
       * @param {double} value Number to be formatted as currency
       * @param {string} locale Local string. Default: 'es-co'
       * @param {string} format Number format. Default: currency
       * @param {string} curr_code Currecy Code: Default: COP
       * @returns 
       */
      getFormattedCurrency(number, locale = 'es-co', format = 'currency', curr_code = 'COP', fraction_digits = 0) {
         return numberStatic.toCurrencyString(number, locale, format, curr_code, fraction_digits)
      },
      /**
       * Rounds the given number
       * @param {Float} value The value to be rounded 
       * @param {Int} minimumFractionDigits The minimum number of fraction digits
       * @param {Int} maximumFractionDigits The maximum number of fraction digits
       * @returns 
       */
      getRoundedDecimal(
         value,
         minimumFractionDigits = 2,
         maximumFractionDigits = 2
      ) {
         return numberStatic.toRoundedDecimal(value, minimumFractionDigits, maximumFractionDigits)
      },
      /**
       * Shows a dialog to confirm deletion of a given object
       * @param {object} item Object to be deleted
       */
      deleteItem(item, prevent_fetch = false, onSave = null) {
         swalStatic.ask(this, function (parent) {
            parent.$store.dispatch(parent.name + '/quickUpdate', {
               id: item.id,
               deleted_at: new Date(),
               prevent_fetch: prevent_fetch,
               onSave: onSave
            })
         })
      },
      /**
       * Shows a dialog to confirm restoring a given object
       * @param {object} item Object to be restored
       */
      restoreItem(item) {
         swalStatic.ask(this, function (parent) {
            parent.$store.dispatch(parent.name + '/restore', {
               id: item.id,
            })
         }, "restaurar", "seguro_que_deseas_restaurar_esto")
      },
      /**
       * Loads more items in the current dialog
       */
      loadMoreItems() {
         this.$store.dispatch(this.name + '/fetch', true)
      },
      /**
       * Tells if a plugin is enabled
       * @param {String} plugin The plugin to be verified
       * @returns Boolean True if plugin exists and is enabled. False otherwise
       */
      pluginIsEnabled(plugin) {
         return this.isNotEmpty(this.$store.getters.getPlugins) && this.$store.getters.getPlugins[plugin] == true
      },
      /**
       * Gets the current date
       */
      getCurrentDate() {
         return dateStatic.getCurrentDate();
      }
   }
}