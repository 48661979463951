import axios from 'axios'
import store from '@/store'
import fileStatic from './file'

/**
 * 
 * @param {Object} payload Object containing information of item being updated.
 * @param payload.parent The parent caller/caller object instance
 * @param payload.data The data that will be sent as http request parameters
 * @param payload.onSave The function that will be executed as closure
 * @param payload.prevent_fetch Wether the parent caller will refetch its listing or not
 */
function fetchEmployees(payload) {

   if (!payload) {
      throw "InvalidPayloadException"
   }

   if (!payload.data) {
      throw "InvalidPayloadDataException"
   }

   if (!payload.parent) {
      throw "InvalidParentException"
   }

   store.dispatch('setLoading', {}, { root: true })

   axios({
      url: 'employees',
      method: "GET",
      params: payload.data,
   })
      .then((response) => {
         
         // Run closure
         if (payload.onFetch && typeof (payload.onFetch === 'function')) {
            payload.onFetch(payload.parent, response)            
         }

         // Run closure
         if (payload.prevent_fetch == undefined || payload.prevent_fetch == false) {
            return store.dispatch(payload.parent.name + '/fetch')
         }
      })
      .finally(() => {
         store.dispatch('setLoading', false, { root: true })
      });
}

function getEmployementLetter(payload) {

   if (!payload) {
      throw "InvalidPayloadException"
   }
   
   if (!payload.data) {
      throw "InvalidPayloadDataException"
   }

   if (!payload.data.employee) {
      throw "InvalidPayloadEmployeeIdException"
   }

   if (!payload.parent) {
      throw "InvalidParentException"
   }

   fileStatic.downloadBlobFile(payload.parent, {
      request_url: 'downloads/employees/employment_letter',
      request_params: {
         id: payload.data.employee.id,
         display_salary: payload.data.display_salary ? true : false,
         address_to: payload.data.address_to ?? null 
      },
      save_name:
         'Comprobante laboral ' +
         payload.data.employee.full_name + 
         '.pdf',
      onResponse: function(response, parent){
         if(payload.onResponse && typeof payload.onResponse === "function"){
            payload.onResponse(response, parent)
         }
      }
   })
}


/**
 * Exports all functions to enable dot notation access
 */
export default {
   fetchEmployees,
   getEmployementLetter
}