import { mapGetters, mapActions } from "vuex"

import colorHelper from "@/libs/mixins/color"
import validationMixin from "@/libs/mixins/validation"
import globalMixin from "@/libs/mixins/global"

import UserEdit from "@/components/User/UserEdit.vue"
import EmployeeEdit from "@/components/Employee/EmployeeEdit.vue"
import WorkContractEdit from "@/components/WorkContract/WorkContractEdit.vue"

import NavigationDrawer from "@/components/Interface/NavigationDrawer.vue"

export default {
  mixins: [
    colorHelper,
    validationMixin,
    globalMixin
  ],
  data: () => ({
    title: "",
    dialogs: {
      employee: {
        edit: false
      },
      user: {
        edit: false
      },
      work_contract: {
        edit: false
      }
    },
  }),
  name: "Home",
  components: {

    UserEdit,
    EmployeeEdit,    
    WorkContractEdit,

    NavigationDrawer

  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: "auth/user",
      abilities: "auth/abilities",
      options: "getOptions",
      isDrawerVisible: 'isDrawerVisible',
    }),
  },
  created() {
    this.title = this.options.company_name
  },
  methods: {
    ...mapActions({
      setDrawerVisible: "setDrawerVisible"
    })
  }
};
