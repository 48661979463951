import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: process.env.VUE_APP_IS_DARK_THEME == "true",
        themes: {
          dark: {
            primary: colors.blue.darken1,
            secondary: '#385F73',
            accent: colors.indigo.base,
            "view-button": colors.pink.base,
            "edit-button": colors.indigo.base,
            "delete-button": colors.red,
            "save-button": colors.green,
            "cancel-button": colors.red.darken4
          },
          light: {
            primary: colors.blue.darken1,
            secondary: '#385F73',
            accent: colors.indigo.base,
            "view-button": colors.pink.base,
            "edit-button": colors.indigo.base,
            "delete-button": colors.red,
            "save-button": colors.green,
            "cancel-button": colors.red.darken4
          },
        },
    },
    icons: {
      iconfont: 'mdi',
    },
})