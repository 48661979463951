/**
 * Shows an information notification, color: blue
 * @param {Object} parent Parent class or object that calls this function
 * @param {String} title Notification title
 * @param {String} text Notification text
 */
export function info(parent, text, title = 'informacion') {

   if (!parent) {
      throw "InvalidParentReferenceException"
   }

   if (!title) {
      throw "InvalidAlertTitleException"
   }

   if (!text) {
      throw "InvalidAlertTextException"
   }

   parent.$notify({
      group: 'info',
      title: parent.$t(title),
      text: parent.$t(text),
      type: 'info',
      ignoreDuplicates: true,
      closeOnClick: true
    });
}

/**
 * Shows an error notification, color: red
 * @param {Object} parent Parent class or object that calls this function
 * @param {String} title Notification title
 * @param {String} text Notification text
 */
 export function error(parent, text, title = 'error') {

   if (!parent) {
      throw "InvalidParentReferenceException"
   }

   if (!title) {
      throw "InvalidAlertTitleException"
   }

   if (!text) {
      throw "InvalidAlertTextException"
   }

   parent.$notify({
      group: 'error',
      title: parent.$t(title),
      text: parent.$t(text),
      type: 'error',
      ignoreDuplicates: true,
      closeOnClick: true
    });
}

/**
 * Shows a success notification, color: green
 * @param {Object} parent Parent class or object that calls this function
 * @param {String} title Notification title
 * @param {String} text Notification text
 */
 export function success(parent, text, title = 'exito') {

   if (!parent) {
      throw "InvalidParentReferenceException"
   }

   if (!title) {
      throw "InvalidAlertTitleException"
   }

   if (!text) {
      throw "InvalidAlertTextException"
   }

   parent.$notify({
      group: 'success',
      title: parent.$t(title),
      type: "success",
      text: parent.$t(text),
      ignoreDuplicates: true,
      closeOnClick: true
    });
}

/**
 * Exports all functions to enable dot notation access
 */
export default {
   info,
   error,
   success
}