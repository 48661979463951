<template>
	<div>
		<RoleEdit
			:visible="dialogs.main_object.edit"
			@hide="
				dialogs.main_object.edit = false
				edited_item = default_item
			"
			:active_item="edited_item"
			v-if="$can('create', 'Role')"
		>
		</RoleEdit>

		<AppBar :title="'Roles y permisos de usuario'">
			<template v-slot:right>
				<v-btn
					dark
					icon
					@click="
						edited_item = Object.assign({}, default_item)
						dialogs.main_object.edit = true
					"
					:title="$t('nuevo')"
					:disabled="!$can('create', 'Role')"
				>
					<v-icon>mdi-plus-circle</v-icon>
				</v-btn>
			</template>
		</AppBar>

		<v-card v-if="$can('list', 'Role')">
			<v-container>			

				<v-row dense>
					<v-col cols="12">
						<v-data-table
							:items="roles"
							:headers="table_headers"
							class="elevation-1"
							mobile-breakpoint="0"
							hide-default-footer
							:options.sync="options"
							:server-items-length="total_items"
							dense
						>
							<template v-slot:[`item.id`]="{ item }">
								<v-chip small label dense color="teal darken-1 white--text">
									{{ item.id }}
								</v-chip>
							</template>

                     <template v-slot:[`item.can_login`]="{ item }">
								{{ item.can_login ? $t("iconos.si") : $t("iconos.no")  }}
							</template>

							<template v-slot:[`item.actions`]="{ item }">
								<v-icon
									medium
									class="mr-2 blue--text edit-button--text"
									v-if="!item.deleted_at"
                           :disabled="item.readonly || !$can('edit', 'Role')"
									@click="
										edited_item = item
										dialogs.main_object.edit = true
									"                           
								>
									mdi-file-document-edit</v-icon
								>
								<v-icon
									medium
									class="mr-2 red--text"
									v-if="!item.deleted_at"
                           :disabled="item.readonly || !$can('delete', 'Role')"
									:title="$t('eliminar_elemento')"
									@click="deleteItem(item)"
								>
									mdi-delete
								</v-icon>

							</template>
						</v-data-table>
					</v-col>
				</v-row>

				<DatatablePagination
					:options="options"
					:total_items="total_items"
					:items="roles"
				>
				</DatatablePagination>

			</v-container>
		</v-card>
		<PermissionRequired v-else />
	</div>
</template>

<script>
	import RoleList from '@/components/Role/RoleList.js'
	export default RoleList
</script>
