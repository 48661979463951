import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import dialogs from './modules/dialogs'

import auth from './modules/auth'

import users from './modules/users'
import clients from './modules/clients'
import patients from './modules/patients'

import roles from './modules/roles'

import options from './modules/options'

import countries from './modules/countries'

import cities from './modules/cities'
import motivational_words from './modules/motivationalWords'

import employees from './modules/employees'
import work_contracts from './modules/workContracts'
import vacation_requests from './modules/vacationRequests'

import { Ability } from '@casl/ability'

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		app,
		dialogs,
		auth,
		
		users,
		clients,

		roles,

		options,
		countries,
		
		cities,
		motivational_words,

		employees,
		work_contracts,
		vacation_requests,
		patients,
		

	},
	getters: {
		ability() {
			return new Ability()
		}
	}
})