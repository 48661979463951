export default {
    methods: {
        /**
         * 
         * @param {String} data The data string to be copied to the clipboard
         * @returns Boolean true if successfully copied to clipboard, Promise if not compatible.
         */
        copyToClipboard(data) {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText)
                return navigator.clipboard.writeText(data);
            return Promise.reject('The Clipboard API is not available.');
        }
    }
}