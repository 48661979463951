import { mapGetters } from "vuex"

export default {
   data: () => ({
      privileged_roles: [
         'super_administrador', 'system'
      ],
      hidden_roles : [
         'administrador', 'super_administrador', 'system'
      ],
      provider_roles : [
         "provider", "transportador", "guia", "agente", "hotel", "agencia_de_viajes", "freelancer", "aseguradora"
      ]
   }),
   methods: {
      canEditUser(user){
         if(this.user.id == user.id) return true
         if(this.user.is_super_administrator) return true
         if(this.user.is_administrator && !this.privileged_roles.includes(user.role)) return true
         if(this.$can('edit', 'Provider') && this.provider_roles.includes(user.role)) return true
         if(this.$can('edit', 'User') && this.systemUserRoles.includes(user.role) && !this.hidden_roles.includes(user.role)) return true
         return false
      },
      canSaveUser(user){
         if( this.hidden_roles.includes(user.role) && !this.sessionIsAdministrator()){
            return false
         }
         return true
      },
      sessionIsSuperAdministrator(){
         return this.user.is_super_administrator
      },
      sessionIsAdministrator(){
         return this.user.is_administrator || this.user.is_super_administrator
      }
   },
   computed: {
      ...mapGetters({
         user: "auth/user"			
      }),
      systemUserRoles(){
         // Filter roles that can login
         let sys_user_roles = []
         this.$store.getters.getRoles.forEach(sr => {
            if(sr.cl == 1){
               sys_user_roles.push(sr.value)
            }
         })
         return sys_user_roles
      }
   }
}