/**
 * Sets the value of a local storage item. Updates it if exists. Creates it otherwise
 * @param {String} key The value key name
 * @param {Object} data The data object to be saved to the local storage
 */
function setLocalStorageItem(key, data) {
   localStorage.setItem(key, JSON.stringify(data))
}

/**
 * Gets the value of a local storage item.
 * @param {String} key The value key name
 */
function getLocalStorageItem(key, parse_json = true) {

   if(window.localStorage === undefined) {
      console.warn("Local storage is not supported");
      return false
   }

   if(parse_json){
      return JSON.parse(window.localStorage.getItem(key))
   }
   
   return window.localStorage.getItem(key)
   
}

export default {
   setLocalStorageItem,
   getLocalStorageItem
}