export default {
	state: {
		motivational_words: [  
         "Trabajar de manera inteligente hará que trabajar duro valga la pena.",
         "Trabajar duro te llevará a la cima, disfrutar el camino te llevará más lejos.",
         "El éxito en la vida no se mide por lo que logras sino por los obstáculos que superas.",
         "Nada es particularmente difícil si lo divides en pequeños trabajos.",
         "El único lugar donde el éxito aparece antes que el trabajo es en el diccionario.",
         "El genio comienza las grandes obras, pero solo el trabajo las acaba.",
         "La felicidad es el verdadero sentimiento de plenitud que se consigue con el trabajo duro.",
         "Somos lo que hacemos cada día. De manera que la excelencia no es un acto, es un hábito.",
         "Nunca hay tiempo suficiente para hacerlo bien, pero siempre hay tiempo suficiente para hacerlo de nuevo.",
         "Si quieres que un trabajo fácil parezca muy difícil, sigue postergándolo.",
         "Enfócate en ser productivo en vez de enfocarte en estar ocupado.",
         "Nunca bajes tus metas, aumenta tus esfuerzos.",
         "El trabajo duro hace que desaparezcan las arrugas de la mente y el espíritu.",
         "Si todo el año fuese fiesta, divertirse sería más aburrido que trabajar.",
         "Una mejor productividad significa menos sudor humano, no más.",
         "El camino al éxito siempre está en construcción.",
         "El éxito es en gran medida la intersección de la suerte y el trabajo duro.",
         "El trabajo en equipo es el secreto que hace que gente común consiga resultados poco comunes.",
         "El éxito consiste en pasar de fracaso en fracaso sin perder entusiasmo.",
         "El éxito no es el final, el fracaso no es fatal: es el coraje por seguir lo que cuenta.",
         "No es lo que logras, es lo que superas. Eso es lo que define tu carrera.",
         "Lo bueno del trabajo en equipo es que siempre tienes a otros de tu lado.",
         "No intentes ser una persona de éxito, busca ser una persona con valores.",
         "No he fracaso nunca, simplemente he encontrado 10.000 formas que no iban a funcionar.",
         "Que no te avergüencen tus fracasos. Aprende de ellos y vuelve a empezar.",
         "Haz lo mejor que puedas en cada tarea, sin importar cuán poco importante pueda parecer en ese momento.",
         "Explota tus talentos, habilidades y fortalezas y haz que el mundo se siente y se dé cuenta.",
         "Si hay algo que te asusta, entonces podría significar que vale la pena intentarlo.",
         "La gente que hace un buen trabajo se siente bien y la gente que hace un trabajo excepcional se siente excepcional. Los logros contribuyen en gran medida a la satisfacción.",
         "Cuando todo parezca ir en contra tuyo, recuerda que el avión despega con el viento en contra, no a favor.",
         "La única forma de hacer un gran trabajo es amar lo que haces.",
         "Dios da el talento; el trabajo transforma el talento en genio.",
         "Trabajo deprisa para vivir despacio.",
         "El éxito es la suma de pequeños esfuerzos repetidos un día sí y otro también.",
         "Lo que separa al individuo talentoso del exitoso es un montón de trabajo duro.",
         "Trabaja duro, sueña a lo grande.",
         "Para ser un gran campeón, debes creer que eres el mejor. Si no lo eres, finge que lo eres.",
         "Nunca es demasiado tarde para ser lo que podrías haber sido.",
         "La confianza en sí mismo es el primer secreto del éxito.",
         "El trabajo que nunca se empieza es el que tarda más en realizarse.",
         "Comienza haciendo lo que es necesario, después lo posible y de repente estarás haciendo lo imposible.",
         "Todas las personas tienen la disposición de trabajar creativamente. Lo que sucede es que la mayoría jamás lo nota.",
         "Da siempre lo mejor que tienes. Lo que plantes ahora, lo cosecharás más tarde.",
         "Trabaja en silencio, haz que el éxito haga todo el ruido.",
         "El éxito no se logra sólo con cualidades especiales. Es sobre todo un trabajo de constancia, de método y de organización.",
         "Solo aquellos que se atreven a fallar mucho pueden lograrlo.",
         "Trabajar en la cosa correcta probablemente es más importante que trabajar duro.",
         "No tengas miedo de abandonar lo bueno para ir a por lo grandioso.",
         "Si solo te atreves a hacer lo de siempre deberás contentarte con lo de siempre.",
     ]
	},
	getters: {
		getMotivacionalWords(state) {
         return state.motivational_words
      },
      getRandomMotivation(state){
         let date = (new Date()).getDate()
         let total = state.motivational_words.length
         let day_constant = total/31
         let current_index = day_constant * date
         return state.motivational_words[parseInt(current_index)]
      }
	}
}

