import dialogLoader from "@/store/loaders/dialog"
import listingLoader from "@/store/loaders/listing";

import { mapState } from "vuex";

import RoleEdit from "./RoleEdit.vue";

import swalStatic from "@/libs/functions/swal";

import AppBar from '@/components/Interface/AppBar';
import DatatablePagination from '@/components/Interface/DatatablePagination';

export default {
	components: {
		AppBar,
		DatatablePagination,
      RoleEdit
	},
	mixins: [
		listingLoader,
		dialogLoader,
	],
	computed: {
		...mapState({
			roles: state => state.roles.list,
			table_headers: state => state.roles.table_headers,
			isLoading: "isLoading",
			total_items: state => state.roles.total_items
		})
	},
	data: () => ({
		name: 'roles',
		dialogs: {
			main_object: {
				list: false,
				edit: false
			},
			room_schedule: {
				list: false,  
				edit: false
			} 
		},
		headers() {
			return [
				{ text: '', value: "id", width: '40px' },
				{ text: "Acciones", value: "actions", width: '80px' },
            { text: "Rol", value: "name", width: '80px' },
            { text: "Inicia sesión", value: "can_login", width: '100px', align: "center" },
            { text: "Descripción del rol", value: "description", width: '400px' }
			]
		}
	}),
   methods: {
		initialise() {
			this.options = Object.assign({}, this.default_options)
		},
		onEditFormSaved() {
			this.$store.dispatch(this.name + '/fetch')
		},
		onListFormHide() {
			this.edited_item = {}
			this.window_visible = false
		},
		deleteItem(item) { 
			swalStatic.ask(this, function (parent) {
				parent.$store.dispatch(parent.name + '/delete', {
					id: item.id
				})
			}, 'eliminar', 'Eliminar rol')
		},
		restoreItem(item) {
			swalStatic.ask(this, function (parent) {
				parent.$store.dispatch(parent.name + '/restore', {
					id: item.id,
				})
			}, "restaurar", "seguro_que_deseas_restaurar_esto")
		}
	}
};
