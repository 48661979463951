export default {
   state: {
      countries: null
   },
   getters: {
      getCountries(state) {
         return state.countries
      }
   },
   mutations: {
		setCountries(state, payload) {
         let countries = payload
         if(countries && countries.length){
            countries.forEach(c => {
               c.search_name_string = '+' + c.dialing_code + ' - ' + c.country
            });
         }
			state.countries = countries
		}
	},
}