<template>
	<v-dialog
		v-model="window_visible"
		transition="fade-transition"
		persistent no-click-animation
		overlay-opacity="0.8"
		:fullscreen="$vuetify.breakpoint.xsOnly"
		:retain-focus="false"
		width="650px"
	>
		<v-app-bar
			:absolute="$vuetify.breakpoint.xsOnly"
			dark
			color="darken-4"
			class="main-color-bg"
		>
			<v-btn icon dark @click="window_visible = false" :title="$t('cancelar')">
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<v-toolbar-title>{{ $t('usuario_o_proveedor') }}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn :disabled="!canSaveUser(edited_item)" icon dark @click="save()" :title="$t('guardar')">
				<v-icon>mdi-content-save</v-icon>
			</v-btn>

		</v-app-bar>

		<v-card>
			<v-card-text>
				<v-container class="app-modal-container">
					<v-row dense>
						<v-col cols="12">
							<v-select
								v-model="edited_item.role"
								:label="i18n.t('rol')"
								:items="getVisibleRoles"
								item-text="text"
								item-value="value"
								dense
							>
							</v-select>
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field
								v-model="edited_item.name"
								:label="i18n.t('nombre')"
								dense
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field
								v-model="edited_item.email"
								:label="i18n.t('email')"
								required
								dense
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field
								v-model="edited_item.document"
								:label="i18n.t('identificacion') + '/' + i18n.t('documento')"
								dense
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6"
							v-if="selectedRole.cl"
						>
							<v-checkbox
								v-model="edited_item.edit_password"
								:label="i18n.t('modificar_contraseña')"
								:readonly="isEmpty(edited_item.id)"
								dense
							></v-checkbox>
						</v-col>

						<v-col cols="12" sm="6" 
							v-if="edited_item.edit_password && selectedRole.cl"
						>
							<v-text-field
								id="password"
								v-model="edited_item.new_password"
								:label="i18n.t('contraseña')"
								dense
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6" 
							v-if="edited_item.edit_password && selectedRole.cl"
						>
							<v-text-field
								id="password_2"
								v-model="edited_item.new_confirm_password"
								:label="i18n.t('contraseña')"
								dense
							></v-text-field>
						</v-col>
						
						<v-col cols="12" sm="6">
							<v-text-field
								v-model="edited_item.phone"
								:label="i18n.t('telefono')"
								dense
							></v-text-field>
						</v-col>


						<v-col cols="12" sm="6">
							<v-select
								v-model="edited_item.status"
								:label="i18n.t('estado')"
								:items="filterStatuses(['activo', 'inactivo'])"
								item-text="text"
								item-value="value"
								:disabled="user.id == edited_item.id"
								dense
							>
							</v-select>
						</v-col>

						<v-col cols="12" sm="6"
							v-if="selectedRole.earns_commission"
						>
							<v-text-field
								v-model="edited_item.sale_commission"
								:label="i18n.t('porcentaje_de_comision')"
								dense
								type="number"
								min="0"
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-textarea
								rows="2"
								outlined
								v-model="edited_item.observations"
								:label="i18n.t('observaciones')"
								dense
							></v-textarea>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import dialogLoader from "@/store/loaders/dialog"
	import validationMixin from "@/libs/mixins/validation"
	import axiosStatic from "@/libs/functions/axios";
	import authorizeMixin from "@/libs/mixins/authorize";


	export default {
		data: () => ({
			name: "users",
			default_item: {
				edit_password: true,
				status: "activo",
				phone: null
			},
		}),
		mixins: [
			dialogLoader,
			validationMixin,
			authorizeMixin
		],
		watch: {
			"window_visible": function(visible){ this.windowVisibleChanged(this, visible)},
			"active_item": function(item){ this.activeItemChanged(this, item)}
		},
		methods: {
			save(){
				if(this.isNotEmpty(this.edited_item.document)){
					this.edited_item.document = this.edited_item.document.replace(/[^a-zA-Z0-9\-]/g, '')
				}
				
				if(this.isEmpty(this.edited_item.id) && this.isEmpty(this.edited_item.password)){
					this.edited_item.new_password = this.edited_item.document
					this.edited_item.new_confirm_password = this.edited_item.document
					this.edited_item.edit_password = true
				}
				axiosStatic.objectSave(this);
			}
		},
		computed: {
			getVisibleRoles(){
				if(this.sessionIsAdministrator()){
					return this.$store.getters.getRoles
				}
				return this.$store.getters.getRoles.filter(r => !this.hidden_roles.includes(r.value))
			},
			selectedRole(){
				if(this.isEmpty(this.edited_item.role)) return {}
				let role = this.$store.getters.getRoles.filter(r => {
					return r.value == this.edited_item.role
				})[0] ?? {}
				return role
			}
		}
	};

</script>
