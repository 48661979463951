import axiosStatic from '@/libs/functions/axios'
import mutationStatic from '@/libs/functions/mutation'

export default {
	namespaced: true,
	state: {
		name: "patients",
		list: [],
		options: {
			skip: 0,
			take: 50,
			search: null,
			filters: null
		},
		table_headers: [],
		total_items: 0
	},
	getters: {
		getOptions(state) {
			return state.options
		}
	},
	watch: {
		"options": function () {
			dispatch("fetch")
		}
	},
	mutations: {
		setList(state, payload) {
			mutationStatic.setItemsList(state, payload)
		},
		setTableHeaders(state, payload) {
			state.table_headers = payload
		},
		setOptions(state, payload) {
			state.options = Object.assign(state.options, payload)
		}
	},
	actions: {
		async fetch({ state }, load_more = false) {
			axiosStatic.objectFetch(state, load_more)
		},
		async quickUpdate({ state }, payload) {
			axiosStatic.objectQuickUpdate(state, payload)
		},
		async restore({ state }, payload) {
			axiosStatic.objectRestore(state, payload)
		},
		setList({ commit }, payload, append) {
			commit('setList', payload, append)
		},
		setTableHeaders({ commit }, payload) {
			commit('setTableHeaders', payload)
		},
		setOptions({ commit }, payload) {
			commit('setOptions', payload)
		}
	}
}
