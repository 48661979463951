<template>
	<div>

		<AppBar :title="'Reportes estadísticos'"/>

		<v-card v-if="$can('list', 'Stat')">
			<v-container class="app-modal-container">
				<v-row>
					<v-col cols="12" xs="12" sm="6" md="4" v-for="(report, index) in reports" :key="'report_' + report.name">

						<ReportForm :report="report" />

					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<PermissionRequired v-else />
	</div>
</template>

<script>
import StatsHome from '@/components/Stats/StatsHome.js'
export default StatsHome
</script>
