import dialogLoader from "@/store/loaders/dialog"

import validationMixin from "@/libs/mixins/validation"
import axiosStatic from "@/libs/functions/axios";

export default {
   data: () => ({
      name: "employees",
      default_item: {
         "birthdate":null,
         "first_name":null,
         "middle_name":null,
         "last_name":null,
         "phone_extension":null,
         "phone":null,
         "address":null,
         "second_contact_name":null,
         "second_contact_phone":null,
         "status":"activo",
      }
   }),
   mixins: [
      dialogLoader,
      validationMixin
   ],
   watch: {
		"window_visible": function(visible){ this.windowVisibleChanged(this, visible)},
		"active_item": function(item){ this.activeItemChanged(this, item)},
      "edited_item.country": function(payload){ 
         if( this.isNotEmpty(this.edited_item.dialing_code) ) return
         this.edited_item.dialing_code = payload && payload.dialing_code?payload.dialing_code:''
      },
      "edited_item.country": function(payload){ 
         if( this.isNotEmpty(this.edited_item.dialing_code) ) return
         this.edited_item.dialing_code = payload && payload.dialing_code?payload.dialing_code:''
      }
	},
   methods: {
      save(){
         if(this.isNotEmpty(this.edited_item.document)){
            this.edited_item.document = this.edited_item.document.replace(/[^a-zA-Z0-9\-]/g, '').toUpperCase()
         }
         if(this.isNotEmpty(this.edited_item.phone)){
            this.edited_item.phone = this.edited_item.phone.replace(/\D/g, '')
         }
         axiosStatic.objectSave(this);
      }
   }
};
