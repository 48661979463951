import dialogLoader from "@/store/loaders/dialog"
import clipboardMixin from "@/libs/mixins/clipboard";

import StatsHome from "./StatsHome.vue"
import ReportForm from "./ReportForm/ReportForm.vue";

import { mapState } from "vuex";

import AppBar from '@/components/Interface/AppBar';


export default {
	components: {
		StatsHome,
		ReportForm,
		AppBar
	},
	mixins: [
		dialogLoader,
		clipboardMixin
	],
	computed: {
		...mapState({
         isLoading: "isLoading",
      })
	},
	data: () => ({
		name: 'stats',
		reports: [
			{
				name: "sale_groups",
				fields: [
					{
						type: 'date',
						name: 'date_from'
					},
					{
						type: 'date',
						name: 'date_to'
					}
				]
			}
		],
		
	}),
	methods: {
		initialise() {
			console.log("Init..")
		}
	}
};
