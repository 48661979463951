<template>
	
	<v-expansion-panels v-model="open_panels" multiple>
		<v-expansion-panel>

			<v-expansion-panel-header>
				<h6>{{ $t("reportes." + report.name + ".name") }}</h6>
			</v-expansion-panel-header>

			<v-expansion-panel-content>

				{{ $t("reportes." + report.name + '.description') }}.

				<div v-for="(field, index) in report.fields">

					<div v-if="field.type == 'date'">
						<v-text-field
							type="date"
							v-model="form_fields[field.name]"
							clearable
							:label="$t('campos_reportes.' + field.name)"
						></v-text-field>
					</div>

					<div v-if="field.type == 'sex'">
						<v-combobox
							:items="['masculino', 'femenino', 'otro']"
							v-model="form_fields[field.name]"
							:label="$t('campos_reportes.' + field.name)"
							clearable
							chips
							outlined
							dense
							deletable-chips		
							hide-selected				
						>
							<template v-slot:item="{ item, index }">
                        {{ $t("sexos." + item) }}
                     </template>
							<template v-slot:selection="data">
								<v-chip
									:key="JSON.stringify(data.item)"
									v-bind="data.attrs"
									:input-value="data.selected"
									:disabled="data.disabled"
									@click:close="data.parent.selectItem(data.item)"
									close
								>
									<v-avatar
										class="accent white--text"
										left
										v-text="data.item.slice(0, 1).toUpperCase()"
									></v-avatar>
									{{ $t("sexos." + data.item) }}									
								</v-chip>
							</template>
						</v-combobox>
					</div>

					<div v-if="field.type == 'number'">
						<v-text-field
							type="number"
							v-model="form_fields[field.name]"
							:label="$t('campos_reportes.' + field.name)"
							clearable
						></v-text-field>
					</div>

					<div v-if="field.type == 'status'">
						<v-combobox
							:items="['programado', 'cancelado', 'finalizado']"
							v-model="form_fields[field.name]"
							:label="$t('campos_reportes.' + field.name)"
							clearable
							multiple					
							chips
							outlined
							dense
							deletable-chips		
							hide-selected				
						>
							<template v-slot:item="{ item, index }">
                        {{ $t("estados." + item) }}
                     </template>
							<template v-slot:selection="data">
								<v-chip
									:key="JSON.stringify(data.item)"
									v-bind="data.attrs"
									:input-value="data.selected"
									:disabled="data.disabled"
									@click:close="data.parent.selectItem(data.item)"
									close
								>
									<v-avatar
										class="accent white--text"
										left
										v-text="data.item.slice(0, 1).toUpperCase()"
									></v-avatar>
									{{ $t("estados." + data.item) }}									
								</v-chip>
							</template>
						</v-combobox>
					</div>

					<div v-if="field.type == 'trip'">
						<v-autocomplete
                     v-model="form_fields[field.name]"
                     :label="$t('campos_reportes.' + field.name)"
                     outlined
                     hide-details
                     dense
                     :items="trips"
                     item-text="name"
                     item-value="id"
                     clearable
                     :search-input.sync="trip_search_string"
                     :no-filter="true"
                     single-line
                  >
                     <template v-slot:selection="{ item, index }">
                        {{ getFormattedDate(item.date, 'short') }} -
                        {{ item.name }} - {{ item.agency.name }}
                     </template>

                     <template v-slot:item="{ item, index }">
                        {{ getFormattedDate(item.date, 'short') }} -
                        {{ item.name }} - {{ item.agency.name }}
                     </template>

                     <template v-slot:no-data>
                        <v-list-item>
                           <v-list-item-content>
                              <v-list-item-title v-if="trip_search_string">
                                 🔎
                                 {{ $t('sin_resultados', [trip_search_string]) }}
                              </v-list-item-title>
										<v-list-item-title v-else>
                                 🔎
                                 {{ $t('ingrese_terminos_busqueda') }}
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                     </template>
                  </v-autocomplete>
					</div>
				</div>

				<v-col class="text-center ma-0">
					<v-btn @click="downloadReport()" color="primary" :disabled="!$can('download', 'Stat')">
						<v-icon>mdi-file-download</v-icon>
						{{ $t('descargar') }}
					</v-btn>
				</v-col>

			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>

</template>

<script>
import StatsHome from '@/components/Stats/ReportForm/ReportForm.js'
export default StatsHome
</script>
