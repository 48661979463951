/**
 * Asks if an item is to be removed and performs a /delete HTTP request to delete the item
 * @param {Object} parent Parent object class calling this function
 * @param {Method} callback The function to be executed after the answer been resolved true
 * @param {String} title The title of the dialog. A language entry key is required. Default: 'eliminar'
 * @param {String} question The question text. 
 *    > Html supported out of the box. 
 *    > A language entry key is required. 
 *    > Default: 'seguro_deseas_eliminar_registro'
 * @param {String} icon The icon to be displayed. Default: 'warning'
 * @returns Boolean True if confirmed, false otherwise
 */
export function ask(parent, callback, title = "eliminar", question = "seguro_deseas_eliminar_registro", icon = "warning", data = null) {

   if (!parent) {
      throw "InvalidParentReferenceException"
   }

   if (!callback) {
      throw "InvalidCallbackException"
   }

   return parent.$swal({
      title: parent.$t(title),
      icon: icon,
      html: parent.$t(question),
      showCancelButton: true,
      confirmButtonText: parent.$t("si"),
      cancelButtonText: parent.$t("no"),
      showClass: {
         backdrop: 'swal2-noanimation', // disable backdrop animation
         popup: '',                     // disable popup animation
         icon: ''                       // disable icon animation
      },
   })
      .then((result) => {
         if (result.isConfirmed) {
            callback(parent, data)
            return true;
         }
         return false
      });
}
/**
 * 
 * @param {Object} parent The parent class calling this method
 * @param {String} message The message to be displayed. A language entry key is required.
 * @returns Swal promise
 */
export function warn(parent, message, message_arguments = []) {

   if (!parent) {
      throw "InvalidParentReferenceException"
   }

   if (!message) {
      throw "InvalidMessageException"
   }

   return parent.$swal({
      title: parent.$t("alerta"),
      text: parent.$t(message, message_arguments),
      icon: "warning"
   });
}
/**
 * 
 * @param {Object} parent The parent class calling this method
 * @param {String} message The message to be displayed. A language entry key is required.
 * @returns Swal promise
 */
export function info(parent, message, title = "informacion", message_arguments = []) {

   if (!parent) {
      throw "InvalidParentReferenceException"
   }

   if (!message) {
      throw "InvalidMessageException"
   }

   return parent.$swal({
      title: parent.$t(title),
      text: parent.$t(message, message_arguments),
      icon: "info"
   });
}
/**
 * 
 * @param {Object} parent The parent class calling this method
 * @param {String} message The message to be displayed. A language entry key is required.
 * @returns Swal promise
 */
 export function input(parent, title, message, message_arguments = [], icon = "info", input_type = 'textarea') {

   if (!parent) {
      throw "InvalidParentReferenceException"
   }

   if (!message) {
      throw "InvalidMessageException"
   }

   return parent.$swal({
      title: parent.$t(title),
      text: parent.$t(message, message_arguments),
      icon: icon,
      input: input_type,
      showCancelButton: true,
      cancelButtonText: parent.$t('cancelar')
   });
}

/**
 * Exports all functions to enable dot notation access
 */
export default {
   ask,
   warn,
   input,
   info
}