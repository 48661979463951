import dialogLoader from "@/store/loaders/dialog"
import validationMixin from "@/libs/mixins/validation"
import axiosStatic from "@/libs/functions/axios";
import { mapState } from "vuex";
import {success as successMessage } from "@/libs/functions/alert";

export default {
   data: () => ({
      name: "roles",
      default_item: {
         name: ""  , 
         role: "",
         can_login: true,
         description: "",
         readonly: false,
         earns_commission: false,
         permissions: []
      }, 
      edited_item: {
         name: ""  ,
         role: "",
         can_login: true,
         description: "",
         readonly: false,
         earns_commission: false,
         permissions: []
      },
      persist_window_after_save: true
   }), 
   mixins: [
      dialogLoader,
      validationMixin 
   ],
   watch: {
      "window_visible": function (visible) { this.windowVisibleChanged(this, visible) },
      "active_item": function (item) { this.activeItemChanged(this, item) },
      "edited_item.name": {
         handler(val){
            this.edited_item.role = (this.edited_item.name ?? "").trim().replace(/[^A-Za-z0-9]/g, '_').toLowerCase();
         }
      }
   },
	methods: {
      save() {
         axiosStatic.objectSave(this,{   
            persist_parent: this.persist_window_after_save,
            onSave: function(parent){

               //parent.edited_item.name = null TODO remove this as only is while debugging
               parent.successMessage(parent, "guardado", "guardado_exitosamente")
            }
         })             
      },
      successMessage: successMessage 
   },
   computed: { 
      ...mapState({
         model_actions: state => state.roles.model_actions
      })
   } 
};
