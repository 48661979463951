<template>
	<div>
		<v-app-bar dense dark class="main-color-bg darken-5">
			<v-spacer></v-spacer>

			<v-toolbar-title>Descarga tu certificado laboral</v-toolbar-title>

			<v-spacer></v-spacer>
		</v-app-bar>

		<v-container class="fill-height fluid">
			<v-row align="center" justify="center">
				<v-col cols="12" md="4" sm="12" class="text-center">
					<img
						src="https://www.imagenesdevidaysalud.com/wp-content/uploads/2021/12/Logo-IVS-300x300.png"
						height="280px"
					/>
				</v-col>
				<v-col cols="12" md="8">
					<v-card>
						<v-card-text>
							<v-form ref="form">
								<label class="mb-3">
									Complete este formulario para solicitar su
									certificado laboral
								</label>
								<v-text-field
									id="email"
									outlined
									dense
									v-model="form.email"
									type="email"
									label="Tu dirección de correo registrada en IVS"
									placeholder="Ingresa aquí tu dirección de correo"
									required
								></v-text-field>

								<v-text-field
									id="birthdate"
									outlined
									dense
									v-model="form.birthdate"
									label="Fecha de nacimiento"
									required
									type="date"
								></v-text-field>

								<v-text-field
									id="birthdate"
									outlined
									dense
									v-model="form.identification"
									label="Número de identificación"
									required
									hide-details
								></v-text-field>

								<v-checkbox
									dense
									v-model="form.display_salary"
									label="Mostrar el valor de sus ingresos en el comprobante"
								>
								</v-checkbox>

								<v-text-field
									id="birthdate"
									v-model="form.address_to"
									outlined
									dense
									label="¿A quién va dirigida la carta?"
									required
								></v-text-field>

								<v-alert
									icon="mdi-alert"
									prominent
									text
									type="warning"
									v-if="error_message"
									class="my-3"
								>
									{{ error_message }}
								</v-alert>

								<v-alert
									icon="mdi-information"
									prominent
									text
									type="success"
									v-if="success_message"
									class="my-3"
								>
									{{ success_message }}
								</v-alert>

								<v-btn
									color="primary"
									@click="submit"
									:disabled="loading"
									:loading="loading"
									block
								>
									Solicitar certificado
								</v-btn>
							</v-form>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script type="text/javascript">
	import { mapActions } from 'vuex'
	import validationMixin from '@/libs/mixins/validation'
	import axiosStatic from '@/libs/functions/axios'
	import employeeStatic from '@/libs/functions/employee'
	import globalMixin from '@/libs/mixins/global'

	export default {
		name: 'signin',
		components: {},
		mixins: [validationMixin, globalMixin],

		data: () => ({
			form: {
				email: null,
				password: null,
			},
			loading: false,
			error_message: null,
			success_message: null
		}),

		created() {
			this.initialize()
		},

		methods: {
			initialize() {
			},

			submit() {
				this.loading = true
				this.error_message = null
				this.success_message = null

				axiosStatic.objectRequest({
					url: 'employees/find',
					params: this.form,
					parent: this,
					prevent_fetch: true,
					prevent_loading: true,
					onResponse: function (response, parent) {

						if (
							parent.isEmpty(response.data.work_contract) || 
							parent.isEmpty(response.data.work_contract.id)
						) {
							parent.error_message =
								'No tienes contratos de trabajo activos en este momento'
							parent.loading = false
							return
						}

						if (
							parent.isNotEmpty(response.data.id) &&
							parent.isNotEmpty(response.data.work_contract)
						) {

							axiosStatic.objectRequest({
								url: "work_contracts/send_email_notification",
								method: "POST",
								parent: parent,
								onResponse: function(response, parent){
									parent.loading = false
									if(response.status === 200){
										parent.success_message = '¡Excelente! Tu certificado ya está en camino y llegará a tu bandeja de correo en unos minutos 😎'
									}
								},
								data: {
									employee_id: response.data.id,
									display_salary: parent.form.display_salary,
									address_to: parent.form.address_to,
								}
							});
						}
					},
					onFail: function (error, parent) {
						parent.loading = false
						switch (error.response.status) {
							case 404:
								parent.success_message = 'No se ha encontrado el empleado con los datos especificados.'
								break

							default:
								break
						}
					},
				})
			},
		},
	}
</script>
