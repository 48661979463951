import objectStatic from '@/libs/functions/object'
import localstorageStatic from '@/libs/mixins/localstorage'

import { mapGetters } from 'vuex'

/**
 * Methods and properties that are mixed into every app dialog instance
 */
export default {
   data: () => ({
      /**
       * Default items for dialogs that edit objects, such as users, hotels, clients, etc
       */
      options: {
         page: 1,
         skip: 0,
         take: 20,
         search: null,
         show_deleted: false,
         filters: {}
      },
      default_options: {
         page: 1,
         skip: 0,
         take: 20,
         search: null,
         show_deleted: false,
         filters: {}
      },
      empty_options: {
         page: 1,
         skip: 0,
         take: 20,
         search: null,
         show_deleted: false,
         filters: {}
      },
      keep_options_on: [
         'trips',
         'sale_commissions',
         'payments'
      ],
      timer: null
   }),
   watch: {
      "options.search" : {
         handler(){
            this.options.page = 1
            this.options.skip = 0
         }
      },
      "options.filters" : {
         handler(){
            if(this.total_items < this.options.take){
               this.options.page = 1
               this.options.skip = 0
            }
         },
         deep: true
      },
      "total_items": {
         handler(){
            let reset = false
            if(this.total_items < this.options.take){
               reset = true
            }

            if(this.options.skip > this.total_items){
               reset = true
            }

            if( reset ){
               this.options.page = 1
               this.options.skip = 0
            }
         },
         deep: true
      },
      "options": {
         handler(payload) {

            this.options.skip = this.getSkip
            payload = this.sanitizeOptions(payload)

            /**
             * Include local storage options only for those modules that support it
             */
            if (this.keep_options_on.includes(this.name)) {
               localstorageStatic.setLocalStorageItem(this.name + '_options', payload)
            }

            this.$store.dispatch(this.name + '/setOptions', payload)

            //If timer has time, clear it
            if (this.timer != null) {
               clearTimeout(this.timer);
            }
            
      
            //Set new timer
            this.timer = setTimeout(() => {
               this.timer = null;
      
               this.$store.dispatch(this.name + '/fetch')

      
            }, 500);
            

         },
         deep: true
      },
      "window_visible": function (visible) {

         this.windowVisibleChanged(this, visible)

         /**
          * Update default options on dialog close
          */
         if (!visible && this.keep_options_on.includes(this.name)) {
            let current_options = localstorageStatic.getLocalStorageItem(this.name + '_options')
            this.default_options = this.sanitizeOptions(current_options)
         }
      },
      "active_item": function (item) { this.activeItemChanged(this, item) },
      "default_options": {
         handler() {
            //throw "Unauthorized update on default_options"
            //console.log("Changed default")
         },
         deep: true
      },
      "empty_options": {
         handler() {
            throw "Unauthorized update on empty_options"
         },
         deep: true
      }
   },
   created() {

      if(this.authenticated){
         this.$store.dispatch('auth/refreshAppData')
      }

      let saved_options = localstorageStatic.getLocalStorageItem(this.name + '_options')

      if (saved_options !== null) {
         this.default_options = objectStatic.cloneObject(saved_options)
      }

      this.$store.dispatch(this.name + '/setTableHeaders', this.headers())
   },
   methods: {
      filterStatuses(required_items) {
         return this.filterStatuses(this.$store.getters.getStatuses, required_items);
      },
      resetOptions() {
         this.options = objectStatic.cloneObject(this.empty_options)
      },
      reloadListing() {
         this.$store.dispatch(this.name + '/fetch')
      },
      /**
       * Sets to null all values that are found to be empty strings ("")
       * @param {Object} options Object that will be sanitized
       */
      sanitizeOptions(options) {
         
         let sanitized = objectStatic.cloneObject(options)

         for (const key in sanitized) {

            switch (typeof sanitized[key]) {
               case 'object':
                  sanitized[key] = this.sanitizeOptions(sanitized[key])
                  break;
               case 'string':
                  if (sanitized[key].trim() == "" || sanitized[key] == null || sanitized[key] == "null") {
                     if(key == 'search'){
                        sanitized[key] = ""
                     }else{
                        delete sanitized[key]
                     }
                  }
                  
                  break;
               default:
                  break;
            }

         }
         return sanitized
      }
   },
   computed: {
      getSkip(){
         return (this.options.page - 1 ?? 0) * (this.options.take ?? 50)     
      },
      ...mapGetters({
         authenticated: 'auth/authenticated',
         user: 'auth/user',
         token: 'auth/token'
      }),
   },
   
}