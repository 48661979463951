export default {
    methods: {
        __d__getStatusColor(status) {
            switch (status) {
                case 'activo':
                case 'programado':
                    return 'green'
                case 'inactivo':
                    return 'black';
                case 'borrador':
                    return 'orange';
                case 'finalizado':
                    return 'blue'
            }
        },
        __d__getClientRatingColor(reservation_count) {
            switch (reservation_count) {
                case 1:
                    return "grey"
                case 2:
                    return 'orange'
                case 3:
                    return 'green'
                case 4:
                    return 'purple'
                case 5:
                default:
                    return 'black'
            }
        },
        __d__getAlphaColor(hex_color, transparency) {
            return hex_color + '' + transparency
        },
        __d__getRandomHexColor() {
            const digits = [7, 8, 9, 'e', 'f']
            let hexCode = "#"
            while (hexCode.length < 7) {
                var rand = Math.round(Math.random() * (digits.length - 1))
                if (rand < 0) rand = 0
                if (rand > 4) rand = 4
                console.log(rand)
                hexCode += digits[rand]
            }
            return hexCode
        }
    }
}