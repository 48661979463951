<template>
	<v-dialog
		v-model="window_visible"
		transition="fade-transition"
		persistent no-click-animation
		overlay-opacity="0.8"
		:fullscreen="$vuetify.breakpoint.xsOnly"
		:retain-focus="false"
		width="650px"
	>
		<v-app-bar
			:absolute="$vuetify.breakpoint.xsOnly"
			dark
			color="darken-4"
			class="main-color-bg"
			dense
		>
			<v-btn
				icon
				dark
				@click="window_visible = false"
				:title="$t('cancelar')"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<v-toolbar-title>{{ 
				edited_item.name
			}}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon dark @click="save()" :title="$t('guardar')">
				<v-icon>mdi-content-save</v-icon>
			</v-btn>
		</v-app-bar>

		<v-card>
			<v-card-text>
				<v-container class="app-modal-container">
					<v-row >
						<v-col cols="12">
							<v-text-field
								dense
								v-model="edited_item.name"
								label="Nombre del rol"
								type="text"
								outlined
								hide-details
							></v-text-field>
						</v-col>

						<!-- <v-col cols="12" sm="6">
							<v-text-field
								dense
								v-model="edited_item.role"
								label="Alias"
								readonly
								type="text"
								outlined
								hide-details
							></v-text-field>
						</v-col> -->

						<v-col cols="12">
							<v-text-field
								dense
								v-model="edited_item.description"
								label="Descripción"
								type="text"
								outlined
								hide-details
							></v-text-field>
						</v-col>

						<v-col cols="12" class="py-3">
							<!-- <v-checkbox
								dense
								v-model="edited_item.can_login"
								label="Puede iniciar sesión en el sistema"
								hide-details
							></v-checkbox> -->
							<input type="checkbox"
								class="form-check-input font-12"
								style="font-size: 18px"
								v-model="edited_item.can_login"
							/> <label class="pt-1">Este perfil de usuario permite iniciar sesión en el sistema.</label>
						</v-col>
					</v-row>

					<v-row dense>

						<v-col cols="12" class="mt-3">
							<h5 class="text-h5">Permisos asignados a este perfil</h5>
						</v-col>

						<v-col cols="12" v-for="(mact_group, mact_idx) in model_actions" :key="mact_idx">
							<h6 class="text-h6 my-2">{{  mact_group.name }}</h6>
							<div
								v-for="(action, action_idx) in mact_group.actions"
								:key="action.slug"
								class="mt-2"
								style="display: inline-block; min-width: 250px; width: 50%;"
							>
								<input type="checkbox"
									class="form-check-input font-12"
									style="font-size: 18px"
									v-model="edited_item.permissions"
									:value="action.slug"
								/> <label>{{ action.name }}</label>

							</div>
							
						</v-col>

						<hr/>

						<v-col cols="12">
							<v-checkbox
								dense
								v-model="persist_window_after_save"
								:label="$t('mantener_abierta_esta_ventana_luego_de_guardar')"
								hide-details
							></v-checkbox>
						</v-col>
					</v-row>

				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import RoleEdit from '@/components/Role/RoleEdit.js'
	export default RoleEdit
</script>
