/**
 * 
 * @param {double} value Number to be formatted as currency
 * @param {string} locale Local string. Default: 'es-co'
 * @param {string} format Number format. Default: currency
 * @param {string} curr_code Currecy Code: Default: COP
 * @returns 
 */
function toCurrencyString(value = 0, locale = 'es-co', format = 'currency', curr_code = 'COP', fraction_digits = 0) {

    if (isNaN(value)) {
        value = parseFloat(value);
    }

    return (
        new Intl.NumberFormat(locale, {
            style: format,
            currency: curr_code,
            minimumFractionDigits: fraction_digits
        }).format(value)
    );
}

/**
 * Rounds the given number
 * @param {Float} value The value to be rounded 
 * @param {Int} minimumFractionDigits The minimum number of fraction digits
 * @param {Int} maximumFractionDigits The maximum number of fraction digits
 * @returns 
 */
export function toRoundedDecimal(
    value,
    minimumFractionDigits,
    maximumFractionDigits
) {
    const formattedValue = value.toLocaleString('en', {
        useGrouping: false,
        minimumFractionDigits,
        maximumFractionDigits
    })
    return formattedValue
}

export default {
    toCurrencyString,
    toRoundedDecimal
}