<template>
	<div>
		<AppBar :title="'Parámetros del sistema'">
		</AppBar>

		<v-card id="main-card">
			<v-container>
				<v-tabs fixed-tabs>
					<v-tab>
						 <v-icon left> mdi-key </v-icon>
						General 
					</v-tab>

					<v-tab>
						 <v-icon left> mdi-cog </v-icon>
						Integraciones 
					</v-tab>


					<v-tab-item name="general">
						<v-card flat>
							<v-card-text>
								<v-row dense>
									<v-col cols="12">
										<h5 class="section-title options-title">
											Configuración del sistema
										</h5>
									</v-col>									

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.company_name
											"
											outlined
											dense
											label="Nombre de la empresa"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.company_logo_url
											"
											outlined
											dense
											label="Url del Logo"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.company_footer_image_url
											"
											outlined
											dense
											label="Url del pie de página"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.company_document
											"
											outlined
											dense
											label="Identificación de la empresa"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.company_document_type
											"
											outlined
											dense
											label="Tipo de identificación"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.hr_image_url
											"
											outlined
											dense
											label="Firma del representante de recursos humanos"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.hr_phone_number
											"
											outlined
											dense
											label="Teléfono de recursos humanos"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.hr_representative
											"
											outlined
											dense
											label="Nombre del representante de recursos humanos"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-text-field
											v-model="
												parameter_options.hr_representative_title
											"
											outlined
											dense
											label="Título o cargo del representante de recursos humanos"
										>
										</v-text-field>
									</v-col>

									<v-col cols="12">
										<v-btn @click="saveOptions()" color="success" class="float-right">
											<v-icon>mdi-content-save</v-icon> Guardar
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>

					<v-tab-item name="integrations">
						<v-card flat class="mt-1 mb-2" >
							<v-row>
								<v-col cols="12" class="py-5">
									<h5 class="section-title options-title">Integraciones</h5>
									<v-alert type="info" dense outlined
										>Aquí se mostrarán las configuraciones de integración de airvi con otros sistemas.</v-alert
									>
								</v-col>
							</v-row>
						</v-card>

						<v-card flat  class="mt-1 mb-2">
							<v-row dense>
								<v-col cols="12">
									<h6>Integración con WordPress</h6>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
										dense
										outlined
										hide-details
										v-model="parameter_options.wordpress_api_url"
										label="Url del sitio de wordpress (incluir / al final)"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
										dense
										outlined
										hide-details
										v-model="parameter_options.wordpress_api_username"
										label="Username"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
										dense
										outlined
										hide-details
										v-model="parameter_options.wordpress_api_password"
										label="Password"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<v-text-field
										dense
										outlined
										hide-details
										v-model="parameter_options.wordpress_api_endpoint"
										label="Endpoint"
									></v-text-field>
								</v-col>
								
							</v-row>
						</v-card>

						<v-card flat class="mt-1 mb-2">
							<v-row>
								<v-col cols="12">
									<v-btn @click="saveOptions()" color="success" class="float-right">
										<v-icon>mdi-content-save</v-icon> Guardar
									</v-btn>
								</v-col>
							</v-row>
						</v-card>

					</v-tab-item>
				</v-tabs>
			</v-container>
		</v-card>
		
	</div>
</template>

<script>
	import dialogLoader from '@/store/loaders/dialog'
	import validationMixin from '@/libs/mixins/validation'


	import { mapActions, mapState } from 'vuex'

	import AppBar from '@/components/Interface/AppBar'

	import authorizeMixin from '@/libs/mixins/authorize'

	import alertStatic from '@/libs/functions/alert'
	import axiosStatic from '@/libs/functions/axios'

	import TipTapEditor from '@/components/Interface/TipTapEditor.vue'

	export default {
		data: () => ({
			name: 'options',
			parameter_options: {},			
			modules: [],
		}),
		created() {
			// Precargamos todas las configuraciones
			this.parameter_options = this.$store.getters.getOptions

		},
		components: {
			AppBar,
			TipTapEditor
		},
		mixins: [dialogLoader, validationMixin, authorizeMixin],
		watch: {
			window_visible: function (visible) {
				this.windowVisibleChanged(this, visible)
			},
			active_item: function (item) {
				this.activeItemChanged(this, item)
			}			
		},
		computed: {
			...mapState({
				agencies: (state) => state.agencies.list,
			}),
			apiPublicUrl(){
				return window.env.VUE_APP_WEB_BASE_URL;
			}
		},
		methods: {
			...mapActions({
				setOptions: 'setOptions',
			}),			
			saveOptions() {
				axiosStatic.objectRequest({
					url: 'options/all/update',
					method: 'POST',
					data: {
						options: this.parameter_options,
					},
					parent: this,
					onResponse: function (response, parent) {
						alertStatic.success(
							parent,
							'Guardado exitosamente',
							'Información'
						)
						parent.$store.dispatch('auth/refreshAppData')
					},
				})
			}
		},
	}
</script>