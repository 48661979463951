import axios from 'axios'
import store from '@/store'

/**
 * Saves an object to the server using the provided parent object and payload.
 * @param {Object} parent - The parent object that contains the edited item and store.
 * @param {Object} [payload={}] - The payload object that contains the data to be saved.
 * @param {string} [payload.url] - The URL to send the request to.
 * @param {Object} [payload.params] - The parameters to include in the request.
 * @param {Object} [payload.data] - The data to be saved.
 * @param {boolean} [payload.persist_parent] - Whether or not to persist the parent object.
 * @param {Function} [payload.onSave] - A function to be called after saving the given object
 */
function objectSave(parent, payload = {}) {
	if (!parent.edited_item && !payload.data) {
		throw 'InvalidEditedItemException'
	}

	if (!parent.name) {
		throw 'InvalidParentNameException'
	}

	if (!parent.$store) {
		throw 'InvalidStoreException'
	}

	parent.startLoading()

	var _edited_item = payload.data
		? payload.data
		: Object.assign({}, parent.edited_item)

	parent.clearErrors()

	parent
		.$axios({
			url: payload.url ? payload.url : (parent.url ?? parent.name),
			method: payload.method ? payload.method : (_edited_item.id ? 'PATCH' : 'PUT'),
			params: payload.params
				? payload.params
				: { id: _edited_item.id ? _edited_item.id : null },
			data: _edited_item,
		})
		.then((response) => {
			parent.clearErrors()

			// Close parent
			if (!payload || !!!payload.persist_parent) {
				parent.window_visible = false
			}

			// Run closure
			if (
				payload &&
				typeof (payload.onSave === 'function') &&
				payload.onSave
			) {
				payload.onSave(parent, response)
			}

			// Run closure
			if (
				payload &&
				typeof (payload.prevent_fetch === 'boolean') &&
				payload.prevent_fetch !== true
			) {
				parent.$store.dispatch(parent.name + '/fetch')
			}
		})
		.catch((e) => {
			parent.validation_errors = parent.getValidationErrorsHtml(e)
		})
		.finally(() => {
			parent.stopLoading()
		})
}

/**
 * Fetches data from the server using the provided state object and loads it into the store.
 * @param {Object} state - The state object containing the options and data list.
 * @param {boolean} load_more - Whether or not to load more data into the list.
 * @throws {InvalidOptionsObjectException} If the options object is invalid.
 * @throws {InvalidParentNameException} If the parent name is invalid.
 * @throws {InvalidSkipValueException} If the skip value is invalid.
 * @throws {InvalidTakeValueException} If the take value is invalid.
 * @throws {InvalidSearchValueException} If the search value is invalid.
 * @throws {InvalidDataListException} If the data
 */
async function objectFetch(state, load_more) {
	if (!state.options) {
		throw 'InvalidOptionsObjectException'
	}

	if (!state.name) {
		throw 'InvalidParentNameException'
	}

	if (state.options.skip === undefined) {
		throw 'InvalidSkipValueException'
	}

	if (!state.options.take) {
		throw 'InvalidTakeValueException'
	}

	if (state.options.search === undefined) {
		throw 'InvalidSearchValueException'
	}

	if (!state.list) {
		throw 'InvalidDataListException'
	}

	let query_params = {
		skip: state.options.skip,
		take: state.options.take,
		search: state.options.search,
		show_deleted: state.options.show_deleted,
		filters: state.options.filters,
	}

	if (load_more) {
		query_params.skip = state.list.length
	}

	store.dispatch('setLoading', {}, { root: true })

	axios({
		url: state.url ?? state.name,
		method: 'GET',
		params: query_params,
	})
		.then((response) => {
			return store.dispatch(state.name + '/setList', {
				data: response.data,
				append: load_more,
			})
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
		})
}

/**
 * Retrieves details of an object from the server using the given state options.
 * @param {Object} state - The state object containing options for the query.
 * @param {number} state.options.skip - The number of items to skip in the query.
 * @param {number} state.options.take - The number of items to take in the query.
 * @param {string} state.options.search - The search term to use in the query.
 * @param {boolean} state.options.show_deleted - Whether or not to include deleted items in the query.
 * @param {Object} state.options.filters - Additional filters to apply to the query.
 * @param {string} state.name - The name of the object to retrieve details for
 */
async function objectDetails(state) {
	let query_params = {
		skip: state.options.skip,
		take: state.options.take,
		search: state.options.search,
		show_deleted: state.options.show_deleted,
		filters: state.options.filters,
	}

	store.dispatch('setLoading', {}, { root: true })
	console.log("Loading started..." + new Date())

	axios({
		url: (state.url ?? state.name) + '/details',
		method: 'GET',
		params: query_params,
	})
		.then((response) => {
			return store.dispatch(state.name + '/setDetails', {
				data: response.data,
			})
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
			console.log("Loading finished..." + new Date())
		})
}

/**
 * Retrieves metadata for a given object and dispatches an event with the data.
 * @param {Object} state - The state object containing the object name and options.
 * @param {string} meta_name - The name of the metadata to retrieve.
 * @param {string} dispatch_event - The name of the event to dispatch with the retrieved data.
 * @returns None
 * @throws {InvalidParentNameException} if the parent name is not valid.
 * @throws {InvalidMetaNameException} if the metadata name is not valid.
 * @throws {InvalidDispatchEventException} if the dispatch event name is not valid.
 */
async function objectMeta(state, meta_name = null, dispatch_event = null) {
	if (!state.name) {
		throw 'InvalidParentNameException'
	}

	if (!meta_name) {
		throw 'InvalidMetaNameException'
	}

	if (!dispatch_event) {
		throw 'InvalidDispatchEventException'
	}

	let query_params = {
		skip: state.options.skip,
		take: state.options.take,
		search: state.options.search,
		show_deleted: state.options.show_deleted,
		filters: state.options.filters,
	}

	store.dispatch('setLoading', {}, { root: true })

	axios({
		url: (state.url ?? state.name) + '/' + meta_name,
		method: 'GET',
		params: query_params,
	})
		.then((response) => {
			return store.dispatch(state.name + '/' + dispatch_event, {
				data: response.data,
			})
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
		})
}

/**
 * Updates an object in the backend using a PATCH request and dispatches a fetch action to update the state.
 * @param {Object} state - The current state of the object being updated.
 * @param {Object} payload - The payload containing the updated data to be sent to the backend.
 * @param {Object} payload.parent - The parent object of the object being updated.
 * @param {Function} payload.onSave - A callback function to be executed after the object is successfully updated.
 * @param {boolean} payload.prevent_fetch - A flag to prevent the fetch action from being dispatched after the object is updated.
 * @throws {InvalidParentNameException} if the parent name is invalid or not provided.
 */
async function objectQuickUpdate(state, payload) {

	let parent = null
	let onSave = null
	let prevent_fetch = false

	if (!state.name) {
		throw 'InvalidParentNameException'
	}

	if (!payload) {
		throw 'InvalidPayloadException'
	}

	/**
	 * Remove parent and onSave from model object to be saved
	 */
	if (!payload.parent && payload.onSave) {
		throw 'InvalidParentException'
	} else {
		parent = payload.parent
		onSave = payload.onSave
		prevent_fetch = payload.prevent_fetch

		delete payload.parent
		delete payload.onSave
		delete payload.prevent_fetch
	}

	/**
	 * Add this rule to prevent validation
	 */
	payload = Object.assign(payload, {
		ignore_validation: true,
	})

	store.dispatch('setLoading', {}, { root: true })

	axios({
		url: (state.url ?? state.name),
		method: 'PATCH',
		params: payload,
	})
		.then((response) => {
			// Run closure
			if (payload && typeof (onSave === 'function') && onSave) {
				if (parent) {
					onSave(parent, response)
				}
			}

			// Run closure
			if (!prevent_fetch) {
				return store.dispatch(state.name + '/fetch')
			}
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
		})
}

/**
 * Sends an HTTP request with the given payload object and handles the response.
 * @param {Object} payload - The payload object containing the request settings.
 * @param {string} payload.url - The URL to send the request to.
 * @param {string} [payload.method='GET'] - The HTTP method to use for the request.
 * @param {boolean} [payload.prevent_loading=false] - Whether or not to prevent the loading spinner from showing.
 * @param {Object} [payload.params={}] - The query parameters to include in the request.
 * @param {Object} [payload.data={}] - The data to include in the request body.
 * @param {Function} payload.onResponse - The callback function
 * @param {Object} payload.parent - The reference to the parent class or object. Default: null
 */
export function objectRequest(payload) {
	let settings = {
		url: null,
		method: 'GET',
		prevent_loading: false,
		params: {},
		data: {},
		onResponse: function (response, parent) {},
		onFail: function (response, parent) {},
		parent: null,
	}

	settings = Object.assign(settings, payload)

	if (!settings.method) {
		throw 'InvalidRequestMethodException'
	}

	if (!settings.parent) {
		throw 'InvalidParentException'
	}

	if (!settings.url) {
		throw 'InvalidUrlException'
	}

	if (!settings.onResponse || typeof settings.onResponse !== 'function') {
		throw 'InvalidOnResponseCallbackException'
	}

	if (!settings.prevent_loading) {
		store.dispatch('setLoading', {}, { root: true })
	}

	settings.parent.clearErrors()

	axios({
		url: settings.url,
		method: settings.method,
		params: settings.params,
		data: settings.data,
	})
		.then((response) => {
			settings.onResponse(response, settings.parent)
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
		})
		.catch((error) => {
			settings.parent.validation_errors = settings.parent.getValidationErrorsHtml(error)
			settings.onFail(error, settings.parent)
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
		})
}

/**
 *
 * Deletes an object from the server using the given payload.
 * @param {Object} state Current state of parent object that calls this function
 * @param {Object} payload Object containing information of item being updated
 *    Sample payload object: {
 *       data: {Object} This object contains the id of the item to be deleted. If this
 *             index is not set, the entires payload object will be used as the 'params' object in
 *             the request. If data index is set, the 'params' object of the request will be 'data'
 *       url: {String} The default request url root can be overriden by this parameter.
 *            If not set, the state.name will used to determine the root of the request url,
 *       parent: {Object} The parent class that is calling this function, used to run callbacks,
 *       persist_parent: {Boolean} Whether to keep the parent window open or not,
 *       prefent_fetch: {Boolean} Whether to prevent the parent windows from fetching the list of main_objects,
 *       onDelete: {Method} The function to be executed as closure
 *    }
 * @throws {InvalidParentNameException} If the parent name is invalid.
 * @throws {InvalidPayloadException} If the payload is invalid.
 * @throws {InvalidObjectIdException} If the object ID is invalid.
 * @throws {DeleteMultipleOptionNotAllowed} If the delete multiple option is not allowed.
 * @returns None
 */
async function objectDelete(state, payload) {
	if (!state.name) {
		throw 'InvalidParentNameException'
	}

	if (!payload) {
		throw 'InvalidPayloadException'
	}

	store.dispatch('setLoading', {}, { root: true })

	let params = {}
	if (payload.data) {
		if (!payload.data.id && !payload.data.delete_multiple) {
			throw 'InvalidObjectIdException'
		}

		if (payload.data.id && payload.data.delete_multiple) {
			throw 'DeleteMultipleOptionNotAllowed'
		}

		params = Object.assign({}, payload.data)
	} else {
		if (!payload.id && !payload.delete_multiple) {
			throw 'InvalidObjectIdException'
		}

		if (payload.id && payload.delete_multiple) {
			throw 'DeleteMultipleOptionNotAllowed'
		}

		params = Object.assign({}, payload)
	}

	axios({
		url: payload.url ? payload.url : (state.url ?? state.name),
		method: 'DELETE',
		params: params,
	})
		.then((response) => {
			if (payload.parent) {
				// Close parent
				if (!payload || !!!payload.persist_parent) {
					payload.parent.window_visible = false
				}

				// Run closure
				if (
					payload &&
					typeof (payload.onDelete === 'function') &&
					payload.onDelete
				) {
					payload.onDelete(payload.parent, response)
				} else {
					console.log('Not a funcion or executable')
				}

				// Run closure
				if (
					payload &&
					typeof (payload.prevent_fetch === 'boolean') &&
					payload.prevent_fetch !== true
				) {
					payload.parent.$store.dispatch(payload.parent.name + '/fetch')
				}
			} else {
				return store.dispatch(state.name + '/fetch')
			}
		})
		.catch((e) => {
			if (payload.parent) {
				payload.parent.validation_errors =
					payload.parent.getValidationErrorsHtml(e)
			} else {
				console.error(e)
				if (e.message) {
					alert(e.message)
				}
			}
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
		})
}

/**
 * Asynchronously restores an object using the given state and payload.
 * @param {Object} state - The state object of the object being restored.
 * @param {Object} payload - The payload object containing the data to restore the object.
 * @throws {InvalidParentNameException} if the state object does not have a name property.
 * @throws {InvalidPayloadException} if the payload object is null or undefined.
 * @returns None
 */
async function objectRestore(state, payload) {
	if (!state.name) {
		throw 'InvalidParentNameException'
	}

	if (!payload) {
		throw 'InvalidPayloadException'
	}

	store.dispatch('setLoading', {}, { root: true })

	axios({
		url: state.name + '/restore',
		method: 'PATCH',
		params: payload,
	})
		.then(() => {
			return store.dispatch(state.name + '/fetch')
		})
		.finally(() => {
			store.dispatch('setLoading', false, { root: true })
		})
}

/**
 * Exports all functions to enable dot notation access
 * A collection of functions for interacting with objects in a database.
 * @module objectFunctions
 * @property {Function} objectSave - Saves an object to the database.
 * @property {Function} objectFetch - Fetches an object from the database.
 * @property {Function} objectQuickUpdate - Updates an object in the database.
 * @property {Function} objectDelete - Deletes an object from the database.
 * @property {Function} objectRestore - Restores a deleted object in the database.
 * @property {Function} objectDetails - Fetches details about an object in the database.
 * @property {Function} objectMeta - Fetches metadata about an object in the database.
 * @property {Function} objectRequest
 */
export default {
	objectSave,
	objectFetch,
	objectQuickUpdate,
	objectDelete,
	objectRestore,
	objectDetails,
	objectMeta,
	objectRequest,
}
