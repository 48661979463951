import axios from 'axios'
import i18n from '@/plugins/i18n'
import store from '..'
import localstorageStatic from '@/libs/mixins/localstorage'

export default {
	namespaced: true,
	state: {
		/**
		 * Authentication token
		 */
		token: null,
		/**
		 * Current user logged in
		 */
		user: null,
		/**
		 * Current logged-in user abilities
		 */
		abilities: null
	},
	getters: {
		authenticated(state) {
			return state.user !== null
		},
		user(state) {
			return state.user
		},
		abilities(state) {
			return state.abilities
		},
		token(state){
			return state.token
		}
	},
	mutations: {
		setToken(state, token) {
			state.token = token
			if (state.token) {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
				localStorage.setItem('token', token)
			} else {
				axios.defaults.headers.common['Authorization'] = null
				localStorage.removeItem('token')
			}
		},

		setUser(state, data) {
			state.user = data
		},

		setAbilities(state, abilities) {
			state.abilities = abilities
			if (state.abilities) {
				store.getters.ability.update(abilities)
			} else {
				store.getters.ability.update([])
			}
		}
	},
	actions: {
		async signIn({ dispatch }, credentials) {
			let response = await axios.post('users/signin', credentials)
			return dispatch('setToken', response.data.token)
		},
		async setToken({ commit, state, dispatch }, token) {
			/**
			 * Check if a token was received
			 */
			if (token) commit('setToken', token)
			if (!state.token) return

			return dispatch('refreshAppData')
		},
		async refreshAppData({ commit, dispatch }) {

			// /**
			//  * Check if a token was received
			//  */

			// Prevent simultaneouos requests
			let app_data_updated = localstorageStatic.getLocalStorageItem('app_data_time') ?? 0;
			if((Date.now() < (app_data_updated + 1000)) ){ // At least two seconds between requests
				return;
			}

			// Update last update time of app data
			localstorageStatic.setLocalStorageItem("app_data_time", Date.now());

			try {

				/**
				 * Update logged-in user abilities
				 */
				let response = await axios.get('users/abilities')
				commit('setUser', response.data.details)
				commit('setAbilities', response.data.abilities)

				/**
				 * Translate all dropdown list entries to local language
				 */

				let translated_roles = []
				for (var i = 0; i < response.data.additional_data.roles.length; i++) {
					translated_roles.push({
						"value": response.data.additional_data.roles[i].role,
						"text": response.data.additional_data.roles[i].name,
						"cl": response.data.additional_data.roles[i].cl,
						"earns_commission": response.data.additional_data.roles[i].earns_commission
					})
				}
				commit('setRoles', translated_roles, { root: true })

				let translated_statuses = []
				for (i = 0; i < response.data.additional_data.statuses.length; i++) {
					translated_statuses.push({
						"value": response.data.additional_data.statuses[i],
						"text": i18n.t("estados." + String(response.data.additional_data.statuses[i])),
					})
				}
				commit('setStatuses', translated_statuses, { root: true })

				let translated_sexes = []
				for (i = 0; i < response.data.additional_data.sexes.length; i++) {
					translated_sexes.push({
						"value": response.data.additional_data.sexes[i],
						"text": i18n.t("sexos." + String(response.data.additional_data.sexes[i])),
					})
				}
				commit('setSexes', translated_sexes, { root: true })

				let parsed_document_types = []
				for (i = 0; i < response.data.additional_data.document_types.length; i++) {
					parsed_document_types.push({
						"value": response.data.additional_data.document_types[i],
						"text": i18n.t("tipos_de_documento." + String(response.data.additional_data.document_types[i])),
					})
				}
				commit('setDocumentTypes', parsed_document_types, { root: true })

				commit('setCountries', response.data.additional_data.countries, { root: true })

				commit('setEmployees', response.data.additional_data.employees, { root: true })

				commit('setOptions', response.data.additional_data.options, { root: true })

				commit('setPlugins', response.data.additional_data.plugins, { root: true })


				return response

			} catch (e) {

				console.log(e)

				dispatch('clearState')

				return false

			}
		},
		async signOut({ dispatch }) {
			/**
			 * Sign out and clear all data entries from local storage and app state.
			 */
			return axios.post('users/signout').then(() => {

				dispatch('clearState')

			})
		},
		clearState({ commit }) {

			commit('setToken', null)
			commit('setUser', null)
			commit('setAbilities', null)

			commit('setOptions', null, { root: true })
			commit('setPlugins', null, { root: true })

			commit('setRoles', null, { root: true })
			commit('setStatuses', null, { root: true })
			commit('setSexes', null, { root: true })
			commit('setDocumentTypes', null, { root: true })

			commit('setEmployees', null, { root: true })
			commit('setContractTypes', null, { root: true })
			
		}
	}
}