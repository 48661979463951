<template>
	<div>
		<WorkContractEdit
			:visible="dialogs.main_object.edit"
			@hide="dialogs.main_object.edit = false"
			:active_item="edited_item"
			v-if="$can('create', 'WorkContract')"
		>
		</WorkContractEdit>

		<AppBar :title="'Contratos de trabajo'">
			<template v-slot:right>
				<v-btn
					dark
					icon
					@click="
						edited_item = Object.assign({}, default_item)
						dialogs.main_object.edit = true
					"
					:title="$t('nuevo')"
					:disabled="!$can('create', 'WorkContract')"
				>
					<v-icon>mdi-plus-circle</v-icon>
				</v-btn>
			</template>
		</AppBar>

		<v-card v-if="$can('list', 'WorkContract')">
			<v-container>
				<v-card class="mb-3 mt-3">
					<v-card-text>
						<v-row dense class="mb-0 pb-0">

							<v-col cols="12" sm="6" md="4" >
								<v-autocomplete
									dense
									v-model="options.filters.employee_id"
									label="Empleado"
									:items="$store.getters.getEmployees"
									item-text="search_string"
									item-value="id"
									:return-object="false"
									outlined
									hide-details
									clearable
								>
								</v-autocomplete>
							</v-col>

							

							<v-col cols="12" sm="6" md="4" >
								<v-select
									dense
									v-model="options.filters.status"
									:label="i18n.t('filtrar_por_estado')"
									:items="
										filterStatuses([
											'vigente',
											'finalizado'
										])
									"
									item-text="text"
									item-value="value"
									:return-object="false"
									multiple
									clearable
									outlined
								></v-select
							></v-col>

							<v-col cols="12" sm="12" md="4" class="text-right">
								<v-btn dark @click="resetOptions()" color="red " small class="mr-1">
									<v-icon>mdi-recycle</v-icon>{{ $t('limpiar') }}
								</v-btn>
								<v-btn dark @click="reloadListing()" color="green" small>
									<v-icon>mdi-sync</v-icon>{{ $t('recargar') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>

				<v-row dense>
					<v-col cols="12">
						<v-data-table
							:items="work_contracts"
							:headers="table_headers"
							class="elevation-1"
							mobile-breakpoint="0"
							hide-default-footer
							:options.sync="options"
							:server-items-length="total_items"
							dense
						>
							<template v-slot:[`item.id`]="{ item }">
								<v-chip small label dense color="teal darken-1 white--text">
									{{ item.id }}
								</v-chip>
							</template>

							<template v-slot:[`item.can_login`]="{ item }">
								{{ (item.can_login && item.status == 'activo') ? $t("iconos.si") : $t("iconos.no")  }}
							</template>


							<template v-slot:[`item.actions`]="{ item }">
								<v-icon
									medium
									v-if="!item.deleted_at"
									class="mr-2 blue--text edit-button--text"
									@click="
										edited_item = item
										dialogs.main_object.edit = true
									"
								>
									mdi-file-document-edit</v-icon
								>

								<v-icon
									medium
									class="mr-2 red--text"
									v-if="!item.deleted_at"
									title="Eliminar"
									@click="deleteItem(item)"
								>
									mdi-delete
								</v-icon>

								<v-icon
									medium
									class="mr-2 red--text"
									v-if="item.deleted_at"
									:title="$t('recuperar_elemento')"
									@click="restoreItem(item)"
								>
									mdi-restore
								</v-icon>
							</template>

							<template v-slot:[`item.role`]="{ item }">
								<span>{{ item.role_name }}</span>
							</template>

							<template v-slot:[`item.contract_type`]="{ item }">
								<span>{{ contractTypes[item.contract_type] }}</span>
							</template>

							<template v-slot:[`item.status`]="{ item }">
								<span>{{ $t('iconos_estados.' + item.status) + ' ' + $t('estados.' + item.status) }}</span>
							</template>

							<template v-slot:[`item.fixed_salary`]="{ item }">
								<span>{{ item.fixed_salary ? 'Sí':'No' }}</span>
							</template>
							
						</v-data-table>
						
					</v-col>
				</v-row>

				<DatatablePagination
					:options="options"
					:total_items="total_items"
					:items="work_contracts"
				>
				</DatatablePagination>
				
			</v-container>
		</v-card>
		<PermissionRequired v-else />
	</div>
</template>

<script>
	import dialogLoader from "@/store/loaders/dialog"
	import listingLoader from "@/store/loaders/listing"

	import WorkContractEdit from "./WorkContractEdit.vue"
	import { mapGetters, mapState } from "vuex"

	import swalStatic from "@/libs/functions/swal"

	import AppBar from '@/components/Interface/AppBar';
	import DatatablePagination from '@/components/Interface/DatatablePagination';

	import validationMixin from "@/libs/mixins/validation"
	import authorizeMixin from "@/libs/mixins/authorize"


	export default {
		components: {
			WorkContractEdit,
			AppBar,
			DatatablePagination
		},
		mixins: [
			listingLoader,
			dialogLoader,
			validationMixin,
			authorizeMixin
		],	
		computed: {
			...mapState({
				work_contracts: state => state.work_contracts.list,
				table_headers: state => state.work_contracts.table_headers,
				total_items: state => state.work_contracts.total_items,
			}),
			contractTypes(){
				let cts = []
				this.$store.getters.getContractTypes.forEach(ct => {
					cts[ct.id] = ct.name					
				});
				console.log(cts)
				return cts
			}
		},	
		data: () => ({
			name: "work_contracts",
			dialogs: {
				main_object: {
					edit: false
				}
			},		
			headers() {
				return [
					{ text: '#', value: "id", width: '40px' },
					{ text: 'Acciones', value: "actions", width: '90px' },
					{ text: "Estado", value: "status", width: '120px' },
					{ text: "Nombre", value: "employee.full_name", width: '180px' },
					{ text: "Tipo de Contrato", value: "contract_type", width: '150px' },
					{ text: "Documento", value: "employee.document", width: '150px' },
					{ text: "Inicio", value: "start_date", width: '150px' },
					{ text: "Ingresos fijos", value: "fixed_salary", width: '150px' }
				]
			}
		}),
		methods: {
			initialise() {
				this.options = Object.assign({}, this.default_options)
			},	
			onEditFormSaved() {
				this.$store.dispatch(this.name + '/fetch')
			},
			onListFormHide() {
				this.edited_item = {}
				this.window_visible = false
			},
			restoreItem(item) {
				swalStatic.ask(this, function (parent) {
					parent.$store.dispatch(parent.name + '/restore', {
						id: item.id,
					})
				}, "restaurar", "seguro_que_deseas_restaurar_esto")
			},
			deactivateItem(item){
				swalStatic.ask(this, function(parent){
					parent.$store.dispatch(parent.name + '/quickUpdate', {
						id: item.id,
						status: 'inactivo'
					})
				}, 'inactivar_elemento', 'deseas_inactivar_este_elemento_pregunta')
			},
			activateItem(item){
				swalStatic.ask(this, function(parent){
					parent.$store.dispatch(parent.name + '/quickUpdate', {
						id: item.id,
						status: 'activo'
					})
				}, 'activar_elemento', 'deseas_activar_este_elemento_pregunta')
			}      
	}
	};

</script>
