import axios from 'axios'
import store from '@/store'
import swal from './swal'
import axiosStatic from '@/libs/functions/axios'

/**
 * 
 * @param {Object} payload Object containing information of item being updated.
 * @param payload.parent The parent caller/caller object instance
 * @param payload.data The data that will be sent as http request parameters
 * @param payload.onSave The function that will be executed as closure
 * @param payload.prevent_fetch Wether the parent caller will refetch its listing or not
 */
async function syncWithPlan(payload) {

   if (!payload) {
      throw "InvalidPayloadException"
   }

   if (!payload.data) {
      throw "InvalidPayloadDataException"
   }

   if (!payload.parent) {
      throw "InvalidParentException"
   }

   store.dispatch('setLoading', {}, { root: true })

   axios({
      url: payload.parent.name + '/syncwithplan',
      method: "PATCH",
      params: payload.data,
   })
      .then((response) => {

         // Run closure
         if (payload.onSave && typeof (payload.onSave === 'function')) {
            payload.onSave(payload.parent, response)
         }

         // Run closure
         if (payload.prevent_fetch == undefined || payload.prevent_fetch == false) {
            return store.dispatch(payload.parent.name + '/fetch')
         }
      })
      .finally(() => {
         store.dispatch('setLoading', false, { root: true })
      });
}


/**
 * 
 * @param {Object} payload Object containing information of item being updated.
 * @param payload.parent The parent caller/caller object instance
 * @param payload.data The data that will be sent as http request parameters
 * @param payload.onSave The function that will be executed as closure
 * @param payload.prevent_fetch Wether the parent caller will refetch its listing or not
 */
async function publishBlogPost(payload) {

   if (!payload) {
      throw "InvalidPayloadException"
   }

   if (!payload.data) {
      throw "InvalidPayloadDataException"
   }

   if (!payload.parent) {
      throw "InvalidParentException"
   }

   //store.dispatch('setLoading', {}, { root: true })

   axios({
      url: payload.parent.name + '/publishblogpost',
      method: "POST",
      params: payload.data,
   })
      .then((response) => {

         if (!response.data.success) {
            swal.warn(payload.parent, response.data.error)
         }

         // Run closure
         if (payload.onSave && typeof (payload.onSave === 'function')) {
            payload.onSave(payload.parent, response)
         }

         // Run closure
         if (payload.prevent_fetch == undefined || payload.prevent_fetch == false) {
            return store.dispatch(payload.parent.name + '/fetch')
         }
      })
      .finally(() => {
         //store.dispatch('setLoading', false, { root: true })
      });
}

/**
 * The color of the trip progress bar
 * @param {Object} trip The trip from which to calculate the progress bar color
 * @returns {String} The progress bar color
 */
export function getProgressBarColor(trip) {
   if (trip.sales_count == 0) return 'indigo';
   if (trip.sales_count > trip.min_quota) return 'green';
   if (trip.sales_count == trip.min_quota) return 'orange';
   if (trip.sales_count < trip.min_quota) return 'red';
}

/**
 * The percentage of sold 
 * @param {Object} trip The trip from which to calculate the progress percentage
 * @returns {Integer} The progress percentage
 */
export function getProgressBarPercentage(trip) {
   if (parseInt(trip.sales_count) == 0) return 0;
   return Math.ceil(trip.confirmed_chairs / (trip.max_quota / 100))
}


export function getProviderPaymentList(payload) {
			
   store.dispatch('setLoading', {}, { root: true })

   axios({
      url: 'trips/providerpayments',
      method: "GET",
      params: payload.params
   })
      .then((response) => { payload.onResponse(response, payload.parent) })
      .finally(() => {
         store.dispatch('setLoading', false, { root: true })
      });
}

export function getProviderBalance(payload) {
   axiosStatic.objectRequest({
      url: 'trips/providersbalance',
      params: payload.params,
      parent: payload.parent,
      onResponse: payload.onResponse
   });
}

export function getTripList(payload) {
			
   store.dispatch('setLoading', {}, { root: true })

   axios({
      url: 'trips',
      method: "GET",
      params: payload.params
   })
      .then((response) => { payload.onResponse(response, payload.parent) })
      .finally(() => {
         store.dispatch('setLoading', false, { root: true })
      });
}

/**
 * Exports all functions to enable dot notation access
 */
export default {
   syncWithPlan,
   publishBlogPost,
   getTripList,
   getProviderBalance,
   getProgressBarColor,
   getProviderPaymentList,
   getProgressBarPercentage,
}