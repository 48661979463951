<template>
	<v-container class="fill-height fluid">
		<v-row align="center" justify="center">
			<v-col>
				<v-card class="mx-auto" max-width="374">
					<v-img
						src="https://intranet.imagenesdevidaysalud.com/wp-content/uploads/2024/03/logo-ivs.png"
						height="180px"
						style="padding:10px"
						aspect-ratio="contain"
					>
					</v-img>

					<v-card-text>
						<v-form ref="form">
							<v-text-field
								id="email"
								v-model="form.email"
								type="email"
								:label="$t('email')"
								:placeholder="$t('ingrese_email_mensaje')"
								required
								outlined
								dense
							></v-text-field>

							<v-text-field
								id="contrasena"
								v-model="form.password"
								:label="$t('contraseña')"
								required
								type="password"
								outlined
								dense
							></v-text-field>

							<v-btn
								color="#440099"
								class="white--text"
								@click="submit"
								:disabled="loading"
								:loading="loading"
								block
							>
								{{ $t('iniciar_sesion') }}
							</v-btn>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { mapActions } from 'vuex'
	import validationMixin from '@/libs/mixins/validation'

	export default {
		name: 'signin',
		components: {},
		mixins: [
			validationMixin
		],

		data: () => ({
			form: {
				email: null,
				password: null,
			},
			loading: false,
		}),

		created() {
			this.initialize()
		},

		methods: {
			...mapActions({
				signIn: 'auth/signIn',
				authenticated: 'auth/authenticated',
			}),

			initialize() {
				this.form.email = process.env.VUE_APP_LOGIN_USER
				this.form.password = process.env.VUE_APP_LOGIN_PASSWORD
			},

			submit() {
				this.loading = true
				this.signIn(this.form)
					.then((result) => {
						if (result) {
							this.$router.replace({
								name: 'home',
								path: '/',
							})
						}
					})
					.catch((e) => {
						if (this.isNotEmpty(e.response) && this.isNotEmpty(e.response.status)) {
							switch (e.response.status) {
								case 401:
								case 403:
									if (e.response.data.message) {
										this.$swal.fire(
											this.$t('error'),
											this.$t(e.response.data.message) || e.message,
											'error'
										)
									}
									break
							} // switch				
							return;			
						}

						if (this.isNotEmpty(e.message)) {
							this.$swal.fire(
								this.$t('error'),
								this.$t(e.message),
								'error'
							);
							return
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
