export default {
	methods: {
		/**
		 * Shows an alert on the screen
		 * @param {Object or String} alertObject The alert object containing alert messages and properties
		 * @param {String} alertTitle In case alertObject is string, this will be the title of the dialog
		 * @param {*} alertType In case alertObject is string, this will be the icon of the dialog
		 * @returns Swal promise
		 */
		showAlert(alertObject, alertTitle = "error", alertType = "error") {
			switch (typeof alertObject) {
				case 'object':
					return this.$swal.fire(this.$t(alertTitle), alertObject.response.data.message || alertObject.message, alertType)
					break
				case 'string':
					return this.$swal.fire(this.$t(alertTitle), alertObject, alertType)
					break
			}
		},
		/**
		 * Determines the error message from a given error object
		 * @param {Object} e The error object to be parsed
		 * @returns String Html with the error message
		 */
		getValidationErrorsHtml(e) {
			console.log(e)
			if (e.response !== undefined && e.response.data !== undefined) {
				if (e.response.data.errors !== undefined) {
					if (Object.keys(e.response.data.errors).length) {
						let errHtml = "<p class='validation-error-list-title text-left'><strong>" + this.$t("validation_errors") + "</strong></p>";
						errHtml += "<ul class='validation-error-list  text-left'>";
						for (const error in e.response.data.errors) {
							errHtml += "<li>" + e.response.data.errors[error][0] + "</li>"
						}
						errHtml += "</ul>"
						return errHtml;
					}
					return null
				} else if (e.response.data.code && e.response.system_message == undefined) {
					let errHtml = '<p class="text-left"><strong>' + this.$t("ocurrio_un_error") + '</strong></p>'
					errHtml += '<p class="text-left left">' + this.$t(e.response.data.message) + '</p>'
					if (e.response.data.object_type !== undefined) {
						errHtml += this.$t("el_tipo_de_objeto_es") + " '" + e.response.data.object_type + "'"
					}
					return errHtml
				} else if (e.response.data.system_message == true) {
					let errHtml = '<p class="text-left"><strong>' + this.$t("informe_detalles_tecnicos_al_administrador") + '</strong></p>'
					errHtml += '<p class="text-left left">' + e.response.data.message + '</p>'
					return errHtml
				}
			}
			else if (e.message !== undefined) {
				let errHtml = '<p class="text-left"><strong>' + this.$t("informe_detalles_tecnicos_al_administrador") + '</strong></p>'
				errHtml += '<p class="text-left left">' + e.message + '</p>'
				if (typeof e.stack !== undefined) {
					errHtml += '<p class="text-left left">' + e.stack + '</p>'
				}
				return errHtml
			}

			// Reset validation errors
			this.validationErrors = null
		},
		/**
		 * Determines if a value is empty or not, based on the given emptyness conditions
		 * @param {Mixed} val The value to be evaluated
		 * @returns Boolean true if the object is considered empty, false if not
		 */
		isEmpty(val) {
			return !!(
				val === null
				|| val === ""
				|| val === "0"
				|| val === -1
				|| val === 0
				|| val === "-1"
				|| val === false
				|| val === "false"
				|| val === "FALSE"
				|| val === "null"
				|| val === "undefined"
				|| val === undefined
				|| (typeof val === 'string' && String(val).trim() === "")
				|| (typeof val === 'object' && Object.keys(val).length === 0)
				|| (typeof val === 'string' && String(val).replace(/ /g, '').length == 0)
				|| (typeof val === 'string' && String(val).replace(/\s/g, '').length == 0)
				|| (typeof val === 'string' && String(val).replace(/\s+/g, '').length == 0)
			)
		},
		/**
		 * Evaluates whether a value is empty or not.
		 * @param {Mixed} val The value to be evaluated 
		 * @returns Boolean True if value is not empty, false if empty
		 */
		isNotEmpty(val){
			return !this.isEmpty(val)
		},
		/**
		 * Clears current class validation errors
		 */
		clearErrors() {
			this.validationErrors = null
		}
	},
	watch: {
		validation_errors: function (val) {
			if (val) {
				let err = this.validation_errors
				this.validation_errors = null
				this.showAlert(err)
			}
		}
	},
	data() {
		return {
			validation_errors: null
		}
	}
}