<template>
	<v-app>
		<notifications group="info" type="info" position="bottom right" />
		<notifications group="error" type="error" position="bottom right" />
		<notifications group="success" type="success" position="bottom right" />

		<v-overlay :value="is_loading" id="main-overlay" opacity="0">
			<v-progress-circular
				:size="35"
				:width="5"
				color="blue"
				indeterminate
			></v-progress-circular>
		</v-overlay>

		<NavigationDrawer></NavigationDrawer>	

		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
	import NavigationDrawer from '@/components/Interface/NavigationDrawer'
	import { mapGetters } from 'vuex'

	export default {
		
		components: {
			NavigationDrawer
		},

		computed: {
			...mapGetters({
				authenticated: 'auth/authenticated',
				user: 'auth/user',
				token: 'auth/token',
				is_loading: 'isLoading',
			}),
		},

		created: function () {
			document.title = process.env.VUE_APP_APPLICATION_NAME			
		},
	}
</script>
