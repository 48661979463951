/**
 * Returns a list of alphanumerically sorted properties of the given object
 * @param {Object} o Object to be sorted
 * @returns Object, sorted version of object
 */
function sortObject(o) {
   var sorted = {},
      key, a = [];

   for (key in o) {
      if (o.hasOwnProperty(key)) {
         a.push(key);
      }
   }

   a.sort();

   for (key = 0; key < a.length; key++) {
      sorted[a[key]] = o[a[key]];
   }

   return sorted;
}

/**
 * Creates and returns a copy of the given object
 * @param {Object} o Object to be cloned
 * @returns Object copy
 */
function cloneObject(o){
   if(o === undefined || o === false || o === null) return null
   return JSON.parse(JSON.stringify(o));
}

export default {
   sortObject,
   cloneObject
}