<template>
   <v-app-bar
      dark
      color="darken-4"
      class="main-color-bg"
      dense
   >
      <GoBackButtons/>
      <slot name="left"></slot>

      <v-spacer></v-spacer>

      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <slot name="right"></slot>
   </v-app-bar>
</template>

<script>
   import GoBackButtons from './GoBackButtons.vue';

   export default {
      props: {
         title: String
      },
      components: {
         GoBackButtons
      }
   }
</script>