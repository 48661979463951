<template>
	<div>
		<EmployeeEdit
			:visible="dialogs.main_object.edit"
			@hide="
				dialogs.main_object.edit = false
				edited_item = {}
			"
			:active_item="edited_item"
		>
		</EmployeeEdit>

		<AppBar :title="'Empleados'">
			<template v-slot:right>
				<v-btn
					dark
					icon
					@click="
						edited_item = Object.assign({}, default_item)
						dialogs.main_object.edit = true
					"
					:title="$t('nuevo')"
					:disabled="!$can('create', 'Employee')"
				>
					<v-icon>mdi-plus-circle</v-icon>
				</v-btn>
			</template>
		</AppBar>

		<v-card v-if="$can('list', 'Employee')">
			<v-container>		
				<v-card class="mb-3 mt-3">
					<v-card-text>
						<v-row dense>
							<v-col cols="12" sm="6" md="4">
								<v-text-field
									v-model="options.search"
									append-icon="mdi-magnify"
									:label="$t('ingrese_terminos_busqueda')"
									outlined
									hide-details
									dense
								></v-text-field>
							</v-col>

							<v-col cols="6" sm="6" md="2">
								<v-checkbox
									class="mt-0 pt-0"
									outlined
									v-model="options.show_deleted"
									:label="i18n.t('mostrar_eliminados')"
									dense
								></v-checkbox>
							</v-col>

							<v-col cols="12" md="6" class="text-right">
								<v-btn dark @click="resetOptions()" color="red " small class="mr-1">
									<v-icon>mdi-recycle</v-icon>{{ $t('limpiar') }}
								</v-btn>
								<v-btn dark @click="reloadListing()" color="green" small>
									<v-icon>mdi-sync</v-icon>{{ $t('recargar') }}
								</v-btn>
							</v-col>

						</v-row>					
					</v-card-text>
				</v-card>	
				
				<v-row dense class="px-0">
					<v-col cols="12" sm="6" md="4" v-for="(item) in employees" v-bind:key="item.id" class="mx-0 mb-2">
						<v-card
							class="mx-auto"
						>
							<v-card-title class="pa-2 mb-0 subtitle-1 indigo white--text">
								{{  item.prefix  }} {{  item.first_name  }} {{  item.last_name  }}
							</v-card-title>
							<v-card-text class="mt-0 body-2" style="position:relative">
								<div>									
									{{ item.country ? item.country.country : '' }}	{{ item.city }}		
									<div v-if="isNotEmpty(item.address)">
										<i>{{  item.address }}</i>
									</div>
								</div>	

								<div>
									<strong>Identificación:</strong> {{ item.document }}									
								</div>

								<div v-if="isNotEmpty(item.phone)">
									{{ item.phone }} 
									<span v-if="isNotEmpty(item.second_contact_phone)"> | {{ item.second_contact_name }} {{ item.second_contact_phone }} </span>
								</div>

								<div v-if="isNotEmpty(item.birthdate)">
									<strong>Nacimiento: </strong> {{ getFormattedDate(item.birthdate) }} 
								</div>

								<div class="mt-2">

									<v-btn small label dense color="teal darken-1 white--text" 
										absolute
										bottom
										right>
										{{ item.id }}
									</v-btn>

									<v-icon
										medium
										v-if="!item.deleted_at"
										class="mr-2 blue--text edit-button--text"
										@click="
											edited_item = item
											dialogs.main_object.edit = true
										"
										:disabled="!$can('edit', 'Employee')"
									>
										mdi-file-document-edit</v-icon
									>
		
									<template>
										<a
											:href="'tel:' + item.phone"
											style="text-decoration: none !important"
											v-if="isNotEmpty(item.phone)"
										>
											<v-icon medium class="mr-2 green--text">
												mdi-phone
											</v-icon>
										</a>
										<a v-else>
											<v-icon medium class="mr-2 gray--text">
												mdi-phone
											</v-icon>
										</a>
									</template>
		
									<v-icon
										medium
										class="mr-2 red--text"
										v-if="!item.deleted_at"
										:title="$t('eliminar_elemento')"
										@click="deleteItem(item)"
										:disabled="!$can('trash', 'Employee')"
									>
										mdi-delete
									</v-icon>

									<v-icon
										medium
										v-if="!item.deleted_at"
										class="mr-2 green--text"
										@click="getEmployementLetter(item)" :disabled="isEmpty(item.active_work_contracts_count)"
										:title="'Descargar comprobante laboral de ' + item.first_name + ' ' + item.last_name"
									>
										mdi-file-download</v-icon
									>
		
									<v-icon
										medium
										class="mr-2 red--text"
										v-if="item.deleted_at"
										:title="$t('recuperar_elemento')"
										@click="restoreItem(item)"
									>
										mdi-restore
									</v-icon>

								</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<DatatablePagination
					:options="options"
					:total_items="total_items"
					:items="employees"
				>
				</DatatablePagination>

			</v-container>
		</v-card>
		<PermissionRequired v-else />
	</div>
</template>

<script>
	import EmployeeList from '@/components/Employee/EmployeeList.js'
import PermissionRequired from '../Interface/PermissionRequired.vue';
	export default EmployeeList
</script>
