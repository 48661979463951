import validationMixin from "@/libs/mixins/validation";

import fileStatic from "@/libs/functions/file";
import globalStatic from "@/store/loaders/dialog";
import tripStatic from "@/libs/functions/trip";

import ReportForm from "./ReportForm.vue"

export default {
	props: {
		report: Object
	},
	components: {
		ReportForm
	},
	mixins: [
		validationMixin,
		globalStatic
	],
	computed: {
	},
	data: () => ({
		form_fields: { },
		open_panels: [0],
		trip_search_string: null,
      trips: [],
		trip_id: null,
		timer: null
	}),
	mounted(){
		this.initialise()
	},
	methods: {
		initialise() {
			this.report.fields.forEach(f => {
				this.form_fields[f.name] = null
			})
		},
		downloadReport(){

			let validation_passed = true;

			this.report.fields.forEach(f => {
				if(this.isNotEmpty(f.required)){
					if(f.required && this.isEmpty(this.form_fields[f.name])){
						alert("Field is required")
						validation_passed = false
						return;
					}					
				}
			})

			if(!validation_passed) return;

			fileStatic.downloadBlobFile(this, {
				request_url: '/downloads/reports/' + this.report.name,
				method: "GET",
				mime_type: (this.isEmpty(this.report.mime_type) ? 'officedocument.spreadsheetml.sheet' : this.report.mime_type),
				request_params	: {
					filters: this.form_fields
				},
				save_name: this.$t("reportes." + this.report.name + ".name") + (this.isEmpty(this.report.file_extension) ? '.xlsx' : this.report.file_extension)
			})
		},
		/**
		 * Gets the trips from the database.
		 */
		fetchTrips() {
         
         tripStatic.getTripList({
            params: {
               search: this.trip_search_string
            },
            parent: this,
            onResponse: function(response, parent){
               parent.trips = response.data.items
            }
         });
		},
	},
	watch: {
      "window_visible": function(val){
         if(val && this.isEmpty(this.trips)){
            this.fetchTrips()
         }
      },
		"trip_search_string": function () {
			
         //If timer has time, clear it
         if (this.timer != null) {
            clearTimeout(this.timer);
         }
   
         //Set new timer
         this.timer = setTimeout(() => {
            this.timer = null;
   
            this.fetchTrips()
   
         }, 500);
		},
	}
};
