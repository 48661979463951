/**
 * 
 * @param {date} date Date to be formatted
 * @param {string} format Custom format to be applied, default: long. 
 *  In case new formats be needed, they myst be added to the options object inside of this method.
 * @param {string} locale Locale string, default: es-CO
 * @param {string} zone Time zone string, default: America/Bogota
 */
function toDateString(value, format = 'long', locale = 'es-CO', time_zone = 'UTC') {

   let options = {
      long: { year: 'numeric', month: 'long', day: 'numeric', timeZone: time_zone },
      month: { month: 'long', timeZone: time_zone },
      year: { year: 'numeric', timeZone: time_zone },
      short: { year: 'numeric', month: 'short', day: 'numeric', timeZone: time_zone },
      day_month: { month: 'long', day: 'numeric', timeZone: time_zone },
      long_with_week_day: { year: 'numeric', month: 'long', day: 'numeric', timeZone: time_zone, weekday: 'long' },
   }

   return new Date(value).toLocaleString(locale, options[format])
}
/**
 * 
 * @param {date} date Date to be formatted
 * @param {string} format Custom format to be applied, default: long. 
 *  In case new formats be needed, they myst be added to the options object inside of this method.
 * @param {string} locale Locale string, default: es-CO
 * @param {string} zone Time zone string, default: America/Bogota
 */
function toTimeString(value, format = 'default', locale = 'es-CO') {

   let options = {
      default: { hour12: true, hour: '2-digit', minute: '2-digit' }
   }

   return new Date(value).toLocaleTimeString(locale, options[format])
}
/**
 * Gets the current date as string - yyyy-mm-dd
 * @returns {String} date
 */
function getCurrentDate(){
   return new Date().toISOString().split('T')[0];
}

export default {
   toDateString,
   toTimeString,
   getCurrentDate
}