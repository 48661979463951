import axios from "./axios";
import store from "@/store";

/**
 * Fetches a file and downloads it to the local filesystem
 * @param {Object} parent The parent class or component calling this methods
 * @param {Object} payload The given parameters
   sample_payload = {
      request_url : "",
      request_params : {},
      save_name : "",
      method : "GET",
      mime_type : 'application/pdf',
      preview : false
   }
 * 
 */
function downloadBlobFile(parent, payload) {
   
   if (!payload) {
      throw "InvalidPayloadException"
   }


   if (!parent.$axios) {
      throw "InvalidParentAxiosInstanceException"
   }

   if (!payload.request_url) {
      throw "InvalidRequestUrlException"
   }

   if (!payload.save_name) {
      throw "InvalidFilenameException"
   }

   

   parent.$axios({
      url: payload.request_url,
      method: payload.method??'GET',
      params: payload.request_params??{},
      responseType: 'blob'
   })
      .then((response) => {
         var fileURL;
         if (!payload.preview) {
            fileURL = window.URL.createObjectURL(new Blob([response.data]), { type: payload.mime_type });
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', payload.save_name);
            document.body.appendChild(fileLink);
            fileLink.click();
         } else {
            var file = new Blob([response.data], { type: mime_type??'application/pdf' });
            fileURL = URL.createObjectURL(file);
            window.open(fileURL);
         }

         if(payload.onResponse && typeof payload.onResponse === "function"){
            payload.onResponse(response, parent);
         }

      })
      .catch(e => {
         parent.validation_errors = parent.getValidationErrorsHtml(e)
      })
      .finally(() => {
         store.dispatch('setLoading', false, { root: true })
      })

}

export default {
   downloadBlobFile
}