export default {
	state: {
		is_loading: false, // Loader is visible?
		/**
		 * Company info
		 */
		options: {},
		/**
		 * List of items used in dropdowns
		 */
		roles: null,
		statuses: null,
		sexes: null,
		plugins: null,
		week_days: [
			'domingo',
			'lunes',
			'martes',
			'miercoles',
			'jueves',
			'viernes',
			'sabado'
		],
		drawer_menu_visible: false,
		colors: [
			'red',
			'pink',
			'purple',
			'deep-purple',
			'indigo',
			'blue',
			'light-blue',
			'cyan',
			'teal',
			'green',
			'light-green',
			'lime',
			'yellow',
			'amber',
			'orange',
			'deep-orange',
			'brown',
			'blue-grey',
			'grey',
			'black'
		],
		contract_types: [
			{
			  "id": "practica_laboral",
			  "name": "PRÁCTICA LABORAL"
			},
			{
			  "id": "indefinido",
			  "name": "A TÉRMINO INDEFINIDO"
			},
			{
			  "id": "fijo",
			  "name": "A TÉRMINO FIJO"
			},
			{
			  "id": "prestacion_de_servicios",
			  "name": "PRESTACION DE SERVICIOS"
			},
			{
			  "id": "temporal",
			  "name": "TEMPORAL"
			},
			{
			  "id": "obra_labor",
			  "name": "OBRA O LABOR"
			}
		 ]
	},
	getters: {
		isLoading(state) {
			return state.is_loading
		},
		getRoles(state) {
			return state.roles
		},
		getStatuses(state) {
			return state.statuses
		},
		getSexes(state) {
			return state.sexes
		},
		getDocumentTypes(state) {
			return state.document_types
		},
		getOptions(state) {
			return state.options
		},
		getPlugins(state) {
			return state.plugins
		},
		isDrawerVisible(state) {
			return state.drawer_menu_visible
		},
		getColors(state){
			return state.colors
		},
		getEmployees(state){
			return state.employees
		},
		getContractTypes(state){
			console.log(state.contract_types)
			return state.contract_types
		}
	},
	mutations: {
		setLoading(state, payload) {
			state.is_loading = payload
		},
		setRoles(state, payload) {
			state.roles = payload
		},
		setStatuses(state, payload) {
			state.statuses = payload
		},
		setSexes(state, payload) {
			state.sexes = payload
		},
		setDocumentTypes(state, payload) {
			state.document_types = payload
		},
		setOptions(state, payload) {
			state.options = payload
		},
		setPlugins(state, payload) {
			state.plugins = payload
		},
		setEmployees(state, payload) {
			state.employees = payload
		},
		setContractTypes(state, payload) {
			console.log(payload)
			state.contract_types = payload
		},
		setDrawerVisible(state, payload) {
			state.drawer_menu_visible = payload
		}
	},
	actions: {
		setLoading({ commit }, payload = true) {
			commit('setLoading', payload)
		},
		setDrawerVisible({ commit }, payload = true) {
			commit('setDrawerVisible', payload)
		},
		setOptions({ commit }, payload) {
			commit('setOptions', payload)
		}
	}
}