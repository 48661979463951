<template>
   <span>
		<v-icon
			class="mr-3"
			@click.stop="setDrawerVisible"
			title="Menú de navegación"
			>mdi-menu
		</v-icon>
		
		
		<v-btn
			icon
			dark
			title="Regresar"
			@click="$router.go(-1)"
			class="hidden-sm-and-down"
		>
			<v-icon>mdi-arrow-left-circle</v-icon>
		</v-btn>

		<router-link to="/"  class="hidden-sm-and-down">
			<v-btn
				icon
				dark
				title="Inicio"
			>
				<v-icon>mdi-home</v-icon>
			</v-btn>			
		</router-link>

		<v-btn
			icon
			dark
			title="Continuar"
			@click="$router.go(1)"
			class="hidden-sm-and-down"
		>
			<v-icon>mdi-arrow-right-circle</v-icon>
		</v-btn>
		
   </span>
</template>

<script>
   import {mapActions} from 'vuex'
   export default {
      methods: {
         ...mapActions({
				setDrawerVisible: "setDrawerVisible"
			})
      }
	}
</script>