<template>
	<v-dialog
		v-model="window_visible"
		fullscreen
		transition="fade-transition"
		persistent no-click-animation
		overlay-opacity="0.8"
		:retain-focus="false"
	>
		<v-app-bar
			:absolute="$vuetify.breakpoint.xsOnly"
			dark
			color="darken-4"
			class="main-color-bg"
			dense
		>
			<v-btn
				icon
				dark
				@click="window_visible = false"
				:title="$t('cancelar')"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<v-toolbar-title>Empleado</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon dark @click="save()" :title="$t('guardar')">
				<v-icon>mdi-content-save</v-icon>
			</v-btn>
		</v-app-bar>

		<v-card>
			<v-card-text>
				<v-container class="app-modal-container">
					<v-row>
						<v-col cols="12">
							<strong>Información del Empleado</strong>
						</v-col>

						<v-col cols="12">
							<v-combobox
								dense
								v-model="edited_item.prefix"
								label="Prefijo"
								:items="['Dr.', 'Dra.', 'Sr.', 'Sra.', 'Srita.']"
								item-value="item"
								item-text="item"
								:return-object="false"
								outlined
								hide-details
							>
							</v-combobox>					
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Primer nombre"
								hide-details
								v-model="edited_item.first_name"
							>
							</v-text-field>						
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Segundo Nombre"
								hide-details
								v-model="edited_item.middle_name"
							>
							</v-text-field>						
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Apellidos"
								hide-details
								v-model="edited_item.last_name"
							>
							</v-text-field>						
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Número de documento"
								hide-details
								v-model="edited_item.document"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Fecha de nacimiento"
								hide-details
								type="date"
								v-model="edited_item.birthdate"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12">
							<strong>Información de contacto</strong>
						</v-col>
						
						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Correo electrónico"
								hide-details
								v-model="edited_item.personal_email"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Dirección física"
								hide-details
								v-model="edited_item.address"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Teléfono fijo"
								hide-details
								v-model="edited_item.land_line"
							>
							</v-text-field>
						</v-col>	
						
						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Teléfono celular"
								hide-details
								v-model="edited_item.phone"
							>
							</v-text-field>
						</v-col>	

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Contacto de emergencia"
								hide-details
								v-model="edited_item.second_contact_name"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12" sm="4">
							<v-text-field
								dense
								outlined
								label="Teléfono del contacto de emergencia"
								hide-details
								v-model="edited_item.second_contact_phone"
							>
							</v-text-field>
						</v-col>

						<v-col cols="12">
							<strong>Estado</strong>
						</v-col>
						
						<v-col cols="12" sm="4">
							<v-select
								dense
								v-model="edited_item.status"
								label="Estado"
								:items="['activo', 'inactivo']"
								item-value="item"
								:return-object="false"
								outlined
								hide-details
							>
								<template v-slot:selection="{ item }">
									{{ $t('iconos_estados.' + item) }} {{ $t('estados.' + item) }}
								</template>

								<template v-slot:item="{ item }">
									{{ $t('iconos_estados.' + item) }} {{ $t('estados.' + item) }}
								</template>
							</v-select>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import EmployeeEdit from '@/components/Employee/EmployeeEdit.js'
	export default EmployeeEdit
</script>
